import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const InputTagsUI = ({ isHidden = false, data, setElements, setData }) => {
  const inputTagsRef = useRef(null);

  const [inputTags, setInputTags] = useState(data);

  useLayoutEffect(() => {
    inputTagsRef.current.data = data.map((value) => value.name);
    inputTagsRef.current.isHidden = isHidden;
    inputTagsRef.current.canEnter = true;
    inputTagsRef.current.addEventListener('setElements', (e) => {
      setElements(e);
      updateTags(e);
    });
    inputTagsRef.current.addEventListener('addElement', addElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setData(inputTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTags])
  
  
  const addElements = (e) => {
    const id = e.detail.key.split('-')[0];
    const name = e.detail.value.name ? e.detail.value.name : e.detail.value;
    const code = e.detail.length <= 0 ? id : (parseInt(id) + 1).toString();
    setInputTags((prev) => ([ ...prev, { name, code } ]))
    inputTagsRef.current.data = [ ...inputTagsRef.current.data, e.detail.value ];
  }

  const updateTags = (e) => {
    inputTagsRef.current.data = e.detail.map(tag => tag.value);
  }

  // @ts-ignore
  return <st-core-tags-inputs ref={inputTagsRef} />;
};

InputTagsUI.propTypes = {
  isHidden: PropTypes.bool,
  data: PropTypes.array.isRequired,
  setElements: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired
};

export { InputTagsUI };
