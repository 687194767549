import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import AuthenticationConnectorInstance from '../adapters/connectors/authentication.connector';

const initialUser = AuthenticationConnectorInstance.getUser();
const initialToken = AuthenticationConnectorInstance.getToken();

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState(initialToken);

  useEffect(() => {
    if (token) {
      (async () => {
        if (!user) {
          const userData = await AuthenticationConnectorInstance.getMe();
          const newUser = AuthenticationConnectorInstance.manageTokenAndUser(userData);
          setUser(newUser);
        }
      })();
    }
  }, [token, user]);

  const onLogout = () => {
    AuthenticationConnectorInstance.onLogout();
    window.location.reload();
  };

  const onLogin = (newToken) => {
    AuthenticationConnectorInstance.onLogin(newToken);
    setToken(newToken);
  };

  const userDataValue = useMemo(() => ({ onLogin, onLogout, user }), [user]);

  return <UserContext.Provider value={userDataValue}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.any,
};

export const useUserContext = () => useContext(UserContext);
