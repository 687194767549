import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { Table, Icon } from '../../../Shared';
import { AreasContext, UserContext } from '../../../../../../context';

import './TableAreas.scss';
import environments from '../../../../../../environments';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const thead = [
  { key: 'name', name: 'Nombre' },
  { key: 'typesInterview', name: 'Tipos de entrevistas y subcategorías' },
  { key: 'leads', name: 'Lideres práctica' },
  { key: 'edit', name: 'Editar', className: 'text-center' },
];

const castBody = (areas) => ({ onEditArea }) =>
  areas
    .map((area) => ({
      code: area.code,
      name: area.name,
      typesInterview: (
        <div className="area">
          {area.interviewTypes.map((interviewType) => (
            <div key={interviewType.code} className=" area-interviewTypes">
              <div className="area-interviewTypes__option">{interviewType.name}</div>
              <div className="area-interviewTypes-subCategory">
                {interviewType.subCategories.map((subCategory, index) => (
                  <div key={subCategory.code + index} className="area-interviewTypes-subCategory__option">
                    {subCategory.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
      leads:
        area.leaders &&
        area.leaders.map((leader) => (
          <OverlayTrigger
            key={leader.pslId}
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">{leader.name}</Tooltip>}
          >
            <img className="leads" alt={leader.name} src={`${environments.imgUrl}${leader.pslId}.jpg`} />
          </OverlayTrigger>
        )),
      leaders: area.leaders
        ? area.leaders.map((item) => ({ ...item, img: `${environments.imgUrl}${item.ldapId}.jpg` }))
        : [],
      edit: <Icon icon="pen" onClick={() => onEditArea(area)} />,
    }))
    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));

export const TableAreas = ({ onEditArea }) => {
  const [data, setData] = useState([]);
  const { user } = useContext(UserContext);
  const { areas } = useContext(AreasContext);

  // Initial load
  useEffect(() => {
    areas && setData(castBody(areas)({ onEditArea }));
  }, [areas, user, onEditArea]);

  if (!areas) {
    return null;
  }
  return <Table data={data} thead={thead} />;
};

TableAreas.propTypes = {
  onEditArea: PropTypes.func.isRequired,
};
