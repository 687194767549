import { AuthenticationFactory } from '../factories/authentication.factory';

function AuthenticationConnector() {
  const AuthenticationAdapter =
    (AuthenticationFactory ? AuthenticationFactory.getInstance() : undefined) || null;

  this.logger = () => {
    return AuthenticationAdapter.logger();
  };

  this.onLogin = (newToken) => {
    return AuthenticationAdapter.onLogin(newToken);
  };

  this.onLogout = () => {
    return AuthenticationAdapter.onLogout();
  };

  this.manageTokenAndUser = (userData) => {
    return AuthenticationAdapter.manageTokenAndUser(userData);
  };

  this.getUser = () => {
    return AuthenticationAdapter.getUser();
  };

  this.setUser = (user) => {
    return AuthenticationAdapter.setUser(user);
  };

  this.setToken = (token) => {
    return AuthenticationAdapter.setToken(token);
  };

  this.getToken = () => {
    return AuthenticationAdapter.getToken();
  };

  // clean user and token
  this.clear = () => {
    return AuthenticationAdapter.clear();
  };

  this.getMe = () => {
    return AuthenticationAdapter.getMe();
  };

  this.getAuthenticationRedirectUrl = () => {
    return AuthenticationAdapter.getAuthenticationRedirectUrl();
  };
}

const AuthenticationConnectorInstance = new AuthenticationConnector();

export default AuthenticationConnectorInstance;
