import React, { useState } from 'react';
import { CreateInterviewer, InterviewersTableSingleRow } from './components';
import './ListInterviewers.scss';

export const ListInterviewers = () => {
  const [showForm, setShowForm] = useState(false);
  const updateShowForm = () => setShowForm(!showForm);

  const [reload, setReload] = useState(false);
  const reloadData = () => setReload(!reload);

  return (
    <div className="container-fluid interviewers-table py-4">
      <div className="card card-table">
        <InterviewersTableSingleRow onCreate={updateShowForm} updateTable={reload} />
        <CreateInterviewer showForm={showForm} closeModal={updateShowForm} onCreated={reloadData} />
      </div>
    </div>
  );
};
