import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../Modal';

// Modal V2
export const ConfirmationModal = ({ onOptionSelected, title, question }) => {
  const buttons = [
    { label: 'No', onclick: () => onOptionSelected(false), auxClass: 'btn-secondary' },
    { label: 'Si', onclick: () => onOptionSelected(true), auxClass: 'btn-primary' },
  ];
  return (
    <Modal show={true} title={title} onClose={() => onOptionSelected(false)} buttons={buttons}>
      <p>{question}</p>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  question: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onOptionSelected: PropTypes.func.isRequired,
};
