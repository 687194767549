import React from 'react';
import PropTypes from 'prop-types';

export const Check = ({ active, onClick }) => (
  <i className="material-icons align-middle" onClick={onClick}>
    {active ? 'check_box' : 'check_box_outline_blank'}
  </i>
);

Check.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
