import React from 'react';
// import { Link } from 'react-router-dom';

import { formatAppointmentDate } from '../../../../utils';
import { PrivateNotes } from '../Interviews/components';
import './MyInterviews.scss';

// const isProfiling = 'profiling-data-form';
const noWrapClass = 'text-nowrap';
const icons = 'text-nowrap text-center';
const tHead = [
  { key: 'employee', name: 'Nombre empleado', sortKey: 'employee.name' },
  { key: 'employeeEmail', name: 'Email empleado', sortKey: 'employee.code' },
  {
    key: 'startDate',
    name: 'Fecha y hora',
    sortKey: 'startDate',
    className: 'testing-classname',
  },
  { key: 'area', name: 'Área', sortKey: 'area.name', className: noWrapClass },
  { key: 'type', name: 'Tipo', sortKey: 'type.name', className: noWrapClass },
  { key: 'level', name: 'Nivel', sortKey: 'level.name' },
  // { key: 'form', name: 'Formulario', className: icons },
  { key: 'notes', name: 'Notas privadas', className: icons },
];

const formatMyInterviewData = ({ _id, area, employee, ...interview }) => ({
  id: _id,
  employee: `${employee.name}`,
  employeeEmail: employee.code,
  startDate: formatAppointmentDate(interview.startDate)(interview.endDate),
  area: area.name,
  type: area.interviewType.name,
  level: area.interviewType.subCategory.name,
  interview: interview,
/*   form:
    area.interviewType.form !== isProfiling ? (
      <Link to={`evaluation/${_id}/${employee.jauthId}/${area.interviewType.form}`}>
        <i className="icon-pen"></i>
      </Link>
    ) : (
      <Link
        to={{
          pathname: `profiling/${_id}/${employee.jauthId}/${area.code}`,
          state: { currentInterview: { _id, area, employee, ...interview } },
        }}
      >
        <i className="icon-pen"></i>
      </Link>
    ), */
  notes: getActions(interview),
});

const getActions = (myInterview) => (
  <div className="d-flex justify-content-center">
    <PrivateNotes notes={myInterview.privateNotes} />
  </div>
);

export { tHead, formatMyInterviewData };
