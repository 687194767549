import React from 'react';
import PropTypes from 'prop-types';

export const Select = ({
  label,
  placeholder = '',
  options,
  register = null,
  error = null,
  visible = true,
  ...selectProps
}) => {
  if (!visible) return null;
  return (
    <div className={['form-group', selectProps.required ? 'required' : null].join(' ')}>
      {label && <label className="form-label">{label}</label>}
      <select
        ref={register}
        className={['custom-select', error ? 'is-invalid' : null].join(' ')}
        {...selectProps}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option key={`${option.value}-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <small className="text-danger">{error.message}</small>}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  register: PropTypes.func,
  error: PropTypes.object,
  visible: PropTypes.bool,
};
