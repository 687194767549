import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components
import { Icon, InputTagsUI, Select } from '../../../Shared';
// utils
import { setDefaultValues } from './utils';
// style
import './InterviewType.scss';

const errorMsg = 'es un campo obligatorio';

export const InterviewType = ({ isEdit = true, forms, interview, removeInterview, changeInterview }) => {
  const { nameInterview, subCategories, form } = setDefaultValues(interview);
  const [name, setName] = useState(nameInterview);
  const [error, setError] = useState({ name: null, subCategories: null });

  useEffect(() => {
    !nameInterview &&
      subCategories.length <= 0 &&
      setError({
        ...error,
        name: { message: `Nombre ${errorMsg}` },
        subCategories: { message: `Subcategorías ${errorMsg}` },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['']);

  const handleChangeName = ({ target }) => {
    !target.value
      ? setError({ ...error, name: { message: `Nombre ${errorMsg}` } })
      : setError({ ...error, name: null });
    setName(target.value);
    changeInterview({ ...interview, name: target.value });
  };

  const handleAddSubCategories = (subCategories) => {
    setError((error)=>({
      ...error,
      subCategories: null
    }));
    changeInterview({
      ...interview,
      subCategories: [...subCategories]
    });
  }
  const handledChangeSubCategory = ({ detail }) => {
    detail.length <= 0
      ? setError((error)=>({ ...error, subCategories: { message: `Subcategorías ${errorMsg}` } }))
      : setError((error)=>({...error, subCategories: null}));
    changeInterview({
      ...interview,
      name,
      subCategories: detail.map(({ key, value }) => {
        const id = key.split('-')[0];
        const name = value.name ? value.name : value;
        const code = subCategories.length <= 0 ? id : (detail.length + parseInt(id)).toString();
        return interview.subCategories.find((value) => value.name === name) || { code, name };
      }),
    });
  };

  const handleChangeForm = ({ target }) => changeInterview({ ...interview, form: target.value });

  return (
    <div className="row AddInterview">
      <div className="AddInterview-form col-11">
        <div className="form-group required form col">
          <label className="form-label">Nombre entrevista</label>
          <input
            className="form-control"
            name="nameInterview"
            value={name}
            onChange={handleChangeName}
            required
          />
          {error.name && <span className="text-danger small">{error.name.message}</span>}
        </div>
        <div className="form col mb-3">
          <label>Subcategorías</label>
          <InputTagsUI isHidden={isEdit} data={subCategories} setElements={handledChangeSubCategory} setData={handleAddSubCategories} />
          {error.subCategories && <span className="text-danger small">{error.subCategories.message}</span>}
        </div>
        <div className="form col">
          <label className="form-label">Formulario</label>
          <Select
            name="form"
            className="form-control"
            value={form}
            onChange={handleChangeForm}
            options={forms}
          />
        </div>
      </div>
      {!isEdit && <Icon icon="trash" onClick={() => removeInterview(interview)} />}
    </div>
  );
};

InterviewType.propTypes = {
  isEdit: PropTypes.bool,
  interview: PropTypes.object.isRequired,
  forms: PropTypes.array.isRequired,
  removeInterview: PropTypes.func.isRequired,
  changeInterview: PropTypes.func.isRequired,
};
