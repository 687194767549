import Cookies from 'js-cookie';
import AuthenticationConnectorInstance from '../adapters/connectors/authentication.connector';

const interviewCookie = 'interview';

/*
 * @deprecated use function clear from AuthenticationConnectorInstance instead
 */
const clear = () => {
  AuthenticationConnectorInstance.clear();
};

/*
 * @deprecated use function clear from AuthenticationConnectorInstance instead
 */
const getUser = () => {
  return AuthenticationConnectorInstance.getUser();
};

const setInterview = (interview) => {
  Cookies.set(interviewCookie, JSON.stringify(interview));
};

const getInterview = () => Cookies.get(interviewCookie);

const clearInterview = () => Cookies.remove(interviewCookie);

/*
 * @deprecated use function clear from AuthenticationConnectorInstance instead
 */
const setUser = (user) => {
  return AuthenticationConnectorInstance.setUser(user);
};

/*
 * @deprecated use function clear from AuthenticationConnectorInstance instead
 */
const getToken = () => {
  return AuthenticationConnectorInstance.getToken();
};

/*
 * @deprecated use function clear from AuthenticationConnectorInstance instead
 */
const setToken = (token) => {
  AuthenticationConnectorInstance.setToken(token);
};

const storageService = {
  clear,
  clearInterview,
  getToken,
  getUser,
  getInterview,
  setToken,
  setUser,
  setInterview,
};
export { storageService };
