import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../Button';

export const ModalLeftFooter = ({ buttons }) => (
  <div className="d-flex justify-content-center bg-light p-4 spaced">
    {buttons.map(({ label, ...button }, i) => (
      <Button key={i} {...button}>
        {label}
      </Button>
    ))}
  </div>
);

ModalLeftFooter.propTypes = {
  buttons: PropTypes.array,
};
