import _ from 'lodash';
import * as yup from 'yup';

import environments from '../../../../../../environments';

import { notificationService } from '../../../../../../services';

const errorMsg = 'es un campo obligatorio';

const toastTitle = (code) =>  ({
  title: code ? 'Editar área de valoración' : 'Crea área de valoración',
  text: code ? 'editó el área de valoración' : 'creó el área de valoración',
})

// format defaultValues
export const setDefaultValues = (obj) => ({
  name: obj.name || '',
  leaders: obj.leaders || [],
  interviewTypes: obj.interviewTypes || [],
});

export const schema = yup.object().shape({
  name: yup.string().required(`Nombre ${errorMsg}`),
  leaders: yup.array().required(`Líderes de práctica ${errorMsg}`),
  interviewTypes: yup.array().required(),
});

export const validateLeaders = (res, area) => {
  const employees = res.map((lead) => ({ name: lead.name, pslId: lead.ldapId || lead.pslId }));
  const areaLeaders = area.leaders.map((lead) => ({ name: lead.name, pslId: lead.pslId }));
  const diff = _.differenceWith([...employees], [...areaLeaders], _.isEqual);
  return diff.map((item) => ({
    ...item,
    img: `${environments.imgUrl}${item.pslId}.jpg`,
  }));
};

export const castInterviewType = ({ interviewTypes }) => {
  let counter = 0;
  return interviewTypes
    ? interviewTypes.map((interviewType) => ({
        ...interviewType,
        key: counter++,
      }))
    : [];
};

export const validateError = (code, request, reload, onClose) => {
  if (!request.error) {
    notificationService.showSuccess(toastTitle(code).title, `Se ${toastTitle(code).text} exitosamente`);
    reload();
    onClose();
    return true;
  }
  request.message.forEach(element => {
    const [key] = Object.entries(element.constraints);
    notificationService.showWarning(key[0], key[1]);
  });
  return false;
}

