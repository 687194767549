/* eslint-disable no-unused-vars */
import * as _ from 'lodash';
import { Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { hamService, notificationService } from '../../../../services';
import PropTypes from 'prop-types';
import { serializeFormData } from '../../../../utils';
import { Button } from '../Shared';
import './FormPreferences.scss';

export const FormPreferences = ({ data: { configuration, _id, isAdmin }, meMode }) => {
  const maxInterviewsPerWeek = _.get(configuration, 'maxInterviewsPerWeek', 0);
  const maxInterviewsPerDay = _.get(configuration, 'maxInterviewsPerDay', 1);
  const [english, setEnglish] = useState(_.get(configuration, 'english', false));
  const [quotes, setQuotes] = useState(_.get(configuration, 'quotes', ''));

  // Update interviewer
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = serializeFormData(e.target);
    const body = { configuration: { ...formData, english, quotes } };
    const result = await hamService.pathInterviewer(meMode ? 'me' : _id, body);
    if (result) {
      notificationService.showSuccess('Actualización exitosa', 'Preferencias guardado con exito');
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="fields-container">
        <Form.Group className="required half-width" controlId="maxInterviewsPerWeek">
          <Form.Label>Max. Entrevistas por semana</Form.Label>
          <Form.Control
            type="number"
            required
            placeholder="0"
            min="0"
            defaultValue={maxInterviewsPerWeek}
            name="maxInterviewsPerWeek"
          />
        </Form.Group>
        <Form.Group className="required half-width" controlId="maxInterviewsPerDay">
          <Form.Label>Max. Entrevistas por día</Form.Label>
          <Form.Control
            type="number"
            placeholder="1"
            min="1"
            required
            name="maxInterviewsPerDay"
            defaultValue={maxInterviewsPerDay}
          />
        </Form.Group>
        <Button type="submit" className="save-btn">
          Guardar
        </Button>
      </div>
      <Form.Group controlId="quotes" className={'pt-1'}>
        <Form.Label>Observaciones</Form.Label>
        <Form.Control
          as={'textarea'}
          rows={3}
          value={quotes}
          // @ts-ignore
          min={''}
          onChange={(e) => setQuotes(e.target.value.slice(0, 255))}
        />
      </Form.Group>
      <Form.Check
        type="switch"
        id="english"
        label="Puede valorar en ingles"
        checked={english}
        name="english"
        disabled={meMode}
        onChange={() => setEnglish(!english)}
      />
    </Form>
  );
};

FormPreferences.propTypes = {
  data: PropTypes.any,
  meMode: PropTypes.bool,
};
