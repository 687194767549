import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { notificationService } from '../../../services';

import { NotificationUI } from './NotificationUI';

const body = document.querySelector('body');

export const NotificationsWrapper = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    notificationService.subscribe((notification) => setNotifications((state) => [...state, notification]));
  }, []);

  const notificationsWrapper = (
    <div aria-live="polite" aria-atomic="true" className="notification-wrapper">
      {notifications.map((notification, key) => (
        <NotificationUI key={key} {...notification} />
      ))}
    </div>
  );

  return createPortal(notificationsWrapper, body);
};
