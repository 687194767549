import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AreaSelector,
  // EnglishChecker,
  SubCategoriesSelector,
  ListInterviewers,
  TypeSelector,
  Calendar,
} from './components';
import { SchedulingConsumer, SchedulingProvider } from './context';
import { Button } from '../Shared';
import { UserContext } from '../../../../context';
import './Scheduling.scss'

export const Scheduling = ({
  editInterview = undefined,
  onCloseCalendar = undefined,
}) => {
  const { user } = useContext(UserContext);
  const [requiredDisponibility, setRequiredDisponibility] = useState([]);
  const updateRequiredDisponibility = (id) => {
    let newArr = [...requiredDisponibility];
    if (newArr.includes(id)) {
      const index = newArr.indexOf(id);
      newArr.splice(index, 1);
    } else {
      newArr.push(id);
    }
    setRequiredDisponibility(newArr);
  };

  return (
    <div className="container-fluid bg-light py-4 mb-4">
      <div className="card card-table">
        <SchedulingProvider editInterviewProp={editInterview}>
          <div className="row bg-light p-3">
            <div className="col-12 col-md-4">
              <AreaSelector />
            </div>
            <div className="col-12 col-md-4">
              <TypeSelector />
            </div>
            <div className="col-12 col-md-4">
              <SubCategoriesSelector />
            </div>
            {/*
            <div className="col">
              <EnglishChecker />
            </div>
            */}
          </div>
          <div className="row mt-3">
            <div className="col">
              <SchedulingConsumer>
                {({ search }) => (
                  <Button onClick={() => search(user)} className="m-auto">
                    Buscar
                  </Button>
                )}
              </SchedulingConsumer>
            </div>
          </div>
          <div className="row">
            <h1 className="font-weight-bold">Agendamiento de entrevistas</h1>
          </div>
          <div className="row">
            <div className="col">
              <ListInterviewers
                requiredDisponibility={requiredDisponibility}
                updateRequiredDisponibility={updateRequiredDisponibility}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Calendar onCloseCalendar={onCloseCalendar} requiredDisponibility={requiredDisponibility} />
            </div>
          </div>
        </SchedulingProvider>
      </div>
    </div>
  );
};

Scheduling.propTypes = {
  editInterview: PropTypes.any,
  onCloseCalendar: PropTypes.any,
  exitScheduling: PropTypes.func,
};
