// @ts-nocheck
import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

export const DropdownUI = ({ isOneRegister = false, isImg = false,valueFilter, list, data, selections }) => {
  const dropdownRef = useRef(null);

  useLayoutEffect(() => {
    dropdownRef.current.isOneRegister = isOneRegister;
    dropdownRef.current.valueFilter = valueFilter;
    dropdownRef.current.isImg = isImg;
    dropdownRef.current.list = list;
    if (data) dropdownRef.current.data = data;
    dropdownRef.current.addEventListener('selections', selections);
  });

  return <st-core-dropdown ref={dropdownRef} />;
};

DropdownUI.propTypes = {
  isOneRegister: PropTypes.bool,
  isImg: PropTypes.bool,
  valueFilter: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  data: PropTypes.array,
  selections: PropTypes.func.isRequired,
};
