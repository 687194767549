import React, { useState, useEffect } from 'react';
import { Configuration } from './components';
import { Sortable } from '../Shared';
import { Alert } from 'react-bootstrap';
import { hamService, notificationService } from '../../../../services';

import './ScheduleAnalysis.scss';

const thead = [
  { key: 'name', name: 'Área', sortKey: '', className: 'text-nowrap' },
  { key: 'openPositions', name: 'Número de posiciones abiertas', sortKey: '' },
  { key: 'profilesRequired', name: 'Número de perfilaciones requeridas', sortKey: '' },
  { key: 'profilesAvailable', name: 'Número de perfilaciones disponibles', sortKey: '' },
  { key: 'technicalRequired', name: 'Número de valoraciones requeridas', sortKey: '' },
  { key: 'technicalAvailable', name: 'Número de valoraciones disponibles', sortKey: '' },
];

const tableName = 'schedule-analysis';

export const ScheduleAnalysis = () => {
  const [report, setReport] = useState([]);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    let isAlive = true;
    hamService.getReport().then((res) => {
      if (isAlive) {
        setConfig(res);
        setReport(res.areas);
      }
    });
    return () => (isAlive = false);
  }, []);

  const onGenerateReport = (data) => {
    if (!config) {
      return hamService
        .createReport(data)
        .then((res) => {
          setConfig(res);
          setReport(res.areas);
          return true;
        })
        .catch(() => {
          notificationService.showWarning('Error', 'Ha ocurrido un error creando el reporte');
          return false;
        });
    } else {
      return hamService
        .updateReport(data)
        .then((res) => {
          setReport(res.areas);
          return true;
        })
        .catch(() => {
          notificationService.showWarning('Error', 'Ha ocurrido un error actualizando el reporte');
          return false;
        });
    }
  };

  return (
    <div className="container-fluid report py-4">
      <Configuration config={config} onGenerateReport={onGenerateReport} disabledUpdateButton={!config} />
      {!report.length ? (
        <Alert variant="warning" className="mt-3">
          No hay datos disponibles
        </Alert>
      ) : (
        <Sortable thead={thead} data={report} totalItems={0} classTableName={tableName} />
      )}
    </div>
  );
};
