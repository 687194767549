import environments from '../environments';
import { request } from './api.service';
import { queryURL } from '../utils';
import { sortBy } from 'lodash';
import AuthenticationConnectorInstance from '../adapters/connectors/authentication.connector';

function authorizedRequest(
  url,
  { method = 'GET', body = undefined, showLoading = true } = {},
  environment = environments.hamUrl,
) {
  return request(`${environment}${url}`, {
    body,
    method,
    showLoading,
  });
}
// Areas

const areasToDict = (areas) =>
  areas.reduce((previous, current) => {
    previous[current.code] = current;
    if (current.levels) {
      current.levels = areasToDict(current.levels);
      current.interviewTypes = areasToDict(current.interviewTypes);
    }
    return previous;
  }, {});

const getAreas = (convertToDict = false) =>
  authorizedRequest(`/api/v1/areas`).then((areas) =>
    convertToDict ? areasToDict(areas) : sortBy(areas, 'name', 'asc'),
  );

const deleteArea = (code) => authorizedRequest(`/api/v1/areas/${code}`, { method: 'DELETE' });

const createArea = (body) => authorizedRequest(`/api/v1/areas`, { body: castArea(body), method: 'POST' });

const updateArea = (code) => (body) =>
  authorizedRequest(`/api/v1/areas/${code}`, { body: castArea(body), method: 'PATCH' });

const castArea = (data) => ({
  ...data,
  interviewTypes: data.interviewTypes.map((interviewType) => ({
    ...interviewType,
    key: undefined,
  })),
});

// My Interviewers
const getMyInterview = ({ id }) => authorizedRequest(`/api/v1/me/interviews?interviewerId=${id}`);

const changeState = ({ _id, state }) =>
  authorizedRequest(`/api/v1/interviews?interviewId=${_id}&state=${state === 1 ? 0 : 1}`, {
    method: 'PATCH',
  });

const setInterviewState = (interviewId) =>
  authorizedRequest(`/api/v1/interviews/interviewer?interviewId=${interviewId}`, { method: 'PATCH' });

// Interviews
const putInterviewer = (body) => authorizedRequest(`/api/v1/interviewers`, { body, method: 'POST' });

const getInterviewers = ({ ...rest }) => {
  const query = queryURL(rest);
  return authorizedRequest(`/api/v1/interviewers${query}`);
};

const pathInterviewer = (id, body) =>
  authorizedRequest(`/api/v1/interviewers/${id}`, { body, method: 'PATCH' });

const getInterviewerById = (id) => authorizedRequest(`/api/v1/interviewers/${id}`);

const deleteInterviewer = (id) => authorizedRequest(`/api/v1/interviewers/${id}`, { method: 'DELETE' });

const getInterviewTypes = () => authorizedRequest('/api/v1/interview-types');

const getInterviews = ({ page, pageSize, sortBy, order }, body) =>
  authorizedRequest(`/api/v1/interviews?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}`, {
    method: 'POST',
    body,
  });

const deleteInterview = (appointmentId, userId) =>
  authorizedRequest(`/api/v1/interview/${appointmentId}?userId=${userId}`, { method: 'DELETE' });

const updateInterview = (appointmentId, body) =>
  authorizedRequest(`/api/v1/interview/${appointmentId}`, { method: 'PATCH', body });
// Reports

const reportsURL = '/api/v1/me/positions-reports';

const getReport = () => authorizedRequest(reportsURL);
const createReport = (body) => authorizedRequest(reportsURL, { body, method: 'POST' });
const updateReport = (body) => authorizedRequest(reportsURL, { body, method: 'PATCH' });

// Bookmarks

const settingsURL = '/api/v1/me/settings';

const getBookmarks = () => authorizedRequest(settingsURL);
const createBookmarks = (body) => authorizedRequest(settingsURL, { body, method: 'POST' });
const updateBookmarks = (body) => authorizedRequest(settingsURL, { body, method: 'PATCH' });

// Roles
const getRoleList = () => authorizedRequest('/api/v1/roles');

// Roles
const getSourceSV = () => authorizedRequest('/api/v1/sourceSV');

// Geoinfo
const geoinfoURL = '/api/v1/geoinfo';

const getCountries = () => authorizedRequest(`${geoinfoURL}/countries`);
const getStates = (countryCode) => authorizedRequest(`${geoinfoURL}/states?countryCode=${countryCode}`);
const getCities = (countryCode) => (stateName) =>
  authorizedRequest(`${geoinfoURL}/cities?countryCode=${countryCode}&stateName=${stateName}`);

// Me

/*
 * @deprecated Use getMe from AuthenticationConnectorInstance instead
 */
const getMe = () => {
  return AuthenticationConnectorInstance.getMe();
};

const hamService = {
  authorizedRequest,
  getAreas,
  deleteArea,
  deleteInterviewer,
  getMyInterview,
  changeState,
  setInterviewState,
  getInterviewerById,
  getInterviewers,
  pathInterviewer,
  putInterviewer,
  createArea,
  updateArea,
  getInterviewTypes,
  getInterviews,
  deleteInterview,
  updateInterview,
  getReport,
  createReport,
  updateReport,
  getBookmarks,
  createBookmarks,
  updateBookmarks,
  getRoleList,
  getCountries,
  getStates,
  getSourceSV,
  getCities,
  getMe,
};

export { hamService };
