import React, { useContext, useState } from 'react';
import { Calendar as ReactCalendar, momentLocalizer } from 'react-big-calendar';

import PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';

import { SchedulingContext } from '../../context';

// import { CreateAppointment } from './components';
// import { UpdateAppointment } from './components/UpdateAppointment';

import './Calendar.scss';
import CreateInterAppointment from './components/CreateInterAppointment';
import { UpdateInterAppointment } from './components/UpdateInterAppointment';

moment.locale('es-co');
const localizer = momentLocalizer(moment);

const loadSchedule = (interviewers) => {
  return _.flatten(
    interviewers.map((interviewer) =>
      ((interviewer.appointments || {}).availability || []).map(([start, end]) => ({
        end: new Date(end),
        interviewer,
        start: new Date(start),
        title: `${interviewer.email} - ${moment(start).format('LT')} - ${moment(end).format('LT')}`,
      })),
    ),
  );
};

const loadOriginalSchedule = (interviewers, requiredInterviewers) => {
  const availability = loadSchedule(interviewers);
  const originalAvailability = interviewers
    .filter((interviewer) => requiredInterviewers.includes(interviewer._id))
    .map((interviewer) =>
      ((interviewer.appointments || {}).originalAvailability || []).map(([start, end]) => ({
        end: new Date(end),
        start: new Date(start),
        interviewer,
        background: true,
      })),
    );
  return _.flatten([...originalAvailability, ...availability]);
};

const eventStyleGetter = () => {
  const classes = ['bg-success', 'bg-danger', 'bg-warning', 'bg-info'];
  let indicator = 0;
  const dictionary = {};
  return ({ interviewer, background }) => {
    if (!(interviewer.email in dictionary)) {
      dictionary[interviewer.email] = classes[indicator];
      indicator++;
      if (indicator > classes.length) {
        indicator = 0;
      }
    }
    return {
      className: `${dictionary[interviewer.email]}${background ? ' event-is-background' : ''}`,
    };
  };
};

export const Calendar = ({ requiredDisponibility, onCloseCalendar = undefined }) => {
  let { interviewers, editInterview } = useContext(SchedulingContext);

  const [activeEvent, setActiveEvent] = useState(null);
  if (!interviewers) {
    return null;
  }
  const onCloseModal = (result) => {
    setActiveEvent(null);
    if (result && onCloseCalendar) {
      onCloseCalendar(true);
    }
  };
  const tooltipText = (tootltipEvent) => {
    return tootltipEvent.interviewer.configuration.quotes
      ? `${tootltipEvent.title}\n${tootltipEvent.interviewer.configuration.quotes}`
      : tootltipEvent.title;
  };
  return interviewers.length ? (
    <>
      {activeEvent && editInterview && (
        <UpdateInterAppointment
          data={{
            start: activeEvent.start,
            end: activeEvent.end,
            interviewer: activeEvent.interviewer,
          }}
          interview={editInterview}
          closeModal={onCloseModal}
          closeCalendar={onCloseCalendar}
        />
      )}
      {activeEvent && !editInterview && (
        <CreateInterAppointment data={activeEvent} closeModal={onCloseModal} />
      )}
      <ReactCalendar
        localizer={localizer}
        step={10}
        events={
          requiredDisponibility.length
            ? loadOriginalSchedule(interviewers, requiredDisponibility)
            : loadSchedule(interviewers)
        }
        startAccessor="start"
        endAccessor="end"
        // @ts-ignore
        style={styles.calendar}
        onSelectEvent={setActiveEvent}
        eventPropGetter={eventStyleGetter()}
        tooltipAccessor={(e) => tooltipText(e)}
      />
    </>
  ) : null;
};

const styles = {
  calendar: {
    height: 500,
  },
};

Calendar.propTypes = {
  requiredDisponibility: PropTypes.array,
  onCloseCalendar: PropTypes.any,
};
