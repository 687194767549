import React, { useState, useEffect, useContext } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import PropTypes from 'prop-types';

// Components
import { Sortable, Icon, TopBar } from '../../../Shared';
import { DeleteInterviewer } from '../DeleteInterviewer';
// services or context
import { hamService } from '../../../../../../services';
import { UserContext } from '../../../../../../context';
// utils
import { areaToData, thead, updateTHead, setAreaSelect, resetTypeLevelSelect } from './utils';
import { useQuery, queryURL } from '../../../../../../utils';

/**
 * Create a row in the table for each interviewType
 * @param {*} param0
 */
const formatInterviewerData =
  ({ areas, path }) =>
  (interviewer, action) => {
    const nameUser = interviewer.name || 'Sin nombre';
    const nameUserLink = <Link to={`${path}/${interviewer._id}`}>{nameUser}</Link>;
    if (!interviewer.areas || !Object.keys(interviewer.areas).length) {
      return {
        name: nameUserLink,
        email: interviewer.email,
        actions: (
          <div className="btn-group">
            <Icon icon="trash" onClick={() => action(interviewer)} />
          </div>
        ),
      };
    }
    return Object.entries(interviewer.areas).map(([keyArea, types], indexArea) => {
      return Object.entries(types).map(([keyType, levels], indexType) => {
        const { area, type, level } = areaToData(areas, keyArea, keyType, levels);
        return {
          name: !indexArea && !indexType && nameUserLink,
          email: !indexArea && !indexType && interviewer.email,
          area: !indexType && area,
          type,
          subCategory: level,
          actions: !indexArea && !indexType && (
            <div className="btn-group">
              <Icon icon="trash" onClick={() => action(interviewer)} />
            </div>
          ),
          className: !!indexArea || !!indexType ? 'is-grouped' : null,
        };
      });
    });
  };

export const InterviewersTableSingleRow = ({ onCreate, updateTable }) => {
  const [interviewers, setInterviewers] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [areas, setAreas] = useState(null);
  const [theadFilter, setTheadFilter] = useState(thead);
  const [areasFilter, setAreasFilter] = useState({});

  const history = useHistory();
  const params = useQuery();
  const { path } = useRouteMatch();
  const { user } = useContext(UserContext);

  const [activeInterviewer, setActiveInterviewer] = useState(undefined);
  const [reloadValue, setReloadValue] = useState(false);
  const reload = () => setReloadValue(!reloadValue);

  const interviewerToData = formatInterviewerData({
    areas,
    path,
  });

  const handleFilters = (e) => {
    updateTHead(e, setTheadFilter, areas);
    const filter =
      e.target.id === 'filterByArea'
        ? { filterByArea: e.target.value }
        : { ...areasFilter, [e.target.id]: e.target.value };
    setAreasFilter(filter);
    const queryParams = { page: 1, ...filter, type: 'filter' };
    const query = queryURL(queryParams);
    history.push(query);
  };

  // Load areas from db
  useEffect(() => {
    let isAlive = true;
    hamService.getAreas(true).then((dbAreas) => {
      setTheadFilter(setAreaSelect(dbAreas));
      return isAlive ? setAreas(dbAreas) : null;
    });
    return () => {
      isAlive = false;
      setTheadFilter(resetTypeLevelSelect());
    };
  }, []);

  // Load data
  useEffect(() => {
    if (!areas) {
      return;
    }
    let isAlive = true;
    hamService.getInterviewers(params).then((res) => {
      if (isAlive) {
        setInterviewers(res.data.map((int) => interviewerToData(int, setActiveInterviewer)).flat(2));
        setTotalItems(res.total);
      }
    });
    return () => {
      isAlive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, areas, path, history.location, updateTable, reloadValue]);

  const onCloseModal = (result) => {
    if (result) {
      reload();
    }
    setActiveInterviewer(undefined);
  };

  return (
    <>
      <TopBar buttonLabel="Añadir Entrevistador" onClickButton={onCreate} />
      <Sortable thead={theadFilter} data={interviewers} totalItems={totalItems} onChange={handleFilters} />
      {!interviewers || !interviewers.length ? (
        <Alert variant="warning" className="mt-3">
          No se encuentran entrevistadores
        </Alert>
      ) : null}
      {activeInterviewer ? (
        <DeleteInterviewer interviewer={activeInterviewer} closeModal={onCloseModal} />
      ) : null}
    </>
  );
};

InterviewersTableSingleRow.propTypes = {
  onCreate: PropTypes.func,
  updateTable: PropTypes.bool,
};
