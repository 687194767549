import { hamService } from '../../../../../services';

const searchInterviewers = ({ area, interviewType, subCategory }) =>
  hamService
    .authorizedRequest(
      `/api/v1/interviewers/available?area=${area.code}&interviewType=${interviewType.code}&subCategory=${
        subCategory.code
      }`,
    )
    .then((response) => (response.error ? { error: response.message } : response.data));

const getAppointments = (id) =>
  hamService
    .authorizedRequest(`/api/v1/interviewers/${id}/availability`)
    .then((response) => (response.error ? { error: response.message } : response));
    
const createInterview = (body) =>
  hamService.authorizedRequest('/api/v1/interviews-create', { body, method: 'POST' });

const createInterviewEvaluator = (body) => hamService.authorizedRequest(`/api/v1/interviewer/${body.idProfilingInterview}/interviews-create`, { body, method: 'POST' });

export const schedulingService = {
  createInterview,
  createInterviewEvaluator,
  getAppointments,
  searchInterviewers,  
};
