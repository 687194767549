import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../Icon';

export const ModalLeftHeader = ({ title, onClose }) => (
  <div className="modal-left-header d-flex justify-content-between align-items-center p-4 text-white spaced">
    <h2 className="text-white">{title}</h2>
    <Icon icon="close-1" onClick={() => onClose(false)} />
  </div>
);

ModalLeftHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};
