import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { employeeService } from '../../../../../services';
import { EvaluationHeader } from '../EvaluationHeader';

export const SkillEvaluationUI = ({
  handleDateChange,
  handleEvaluatorChange,
  handleSubmit,
  form,
  date,
  skills,
  evaluated,
  skillData,
  evaluator,
  evaluators,
  isTemplate,
  saveDataAgain,
  isClean,
}) => {
  const evaluationRef = useRef(null);

  const addSingleSkill = async (skillId) => {
    const skill = await employeeService.getSkill(skillId.detail);
    evaluationRef.current.newSkill = skill[0];
  };

  useEffect(() => {
    evaluationRef.current.form = form;
    evaluationRef.current.evaluated = evaluated;
    evaluationRef.current.skillData = skillData;
    evaluationRef.current.completeData = skills;
    evaluationRef.current.addEventListener('submitEvent', handleSubmit);
    evaluationRef.current.addEventListener('addNewSkill', addSingleSkill);
    evaluationRef.current.addEventListener('savePartial', () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isClean && evaluationRef.current.clearData();
  }, [isClean]);

  useEffect(() => {
    if (saveDataAgain.length > 0) {
      evaluationRef.current.savePartial();
    }
  }, [saveDataAgain]);

  // @ts-ignore
  const handleSaveForm = () => evaluationRef.current.shadowRoot.querySelector('#surveyButton').click();

  return (
    <>
      <EvaluationHeader
        date={date}
        evaluator={evaluator}
        evaluators={evaluators}
        onChangeEvaluator={handleEvaluatorChange}
        onChangeDate={handleDateChange}
        onClickSave={handleSaveForm}
        isTemplate={isTemplate}
      />
      <div className="d-flex">
        <div className="flex-grow-1 overflow-auto" lang="es">
          {/* @ts-ignore */}
          <st-core-skill-evaluation ref={evaluationRef} identification="surveyButton" />
        </div>
      </div>
    </>
  );
};

SkillEvaluationUI.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleEvaluatorChange: PropTypes.func.isRequired,
  evaluator: PropTypes.any.isRequired,
  evaluators: PropTypes.any.isRequired,
  date: PropTypes.any.isRequired,
  form: PropTypes.string,
  skills: PropTypes.array.isRequired,
  isTemplate: PropTypes.bool,
  evaluated: PropTypes.string.isRequired,
  skillData: PropTypes.array.isRequired,
  saveDataAgain: PropTypes.array,
  isClean: PropTypes.bool.isRequired,
};
