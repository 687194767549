import { useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { FormPreferences, CheckAreas } from './';
import React, { useContext, useEffect, useState } from 'react';
import { WrapperPage } from '../Shared';
import { hamService } from '../../../../services';
import { HamPermissions } from '../../../../utils';
import { UserContext } from '../../../../context';

const InterviewerEdit = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const role = user.roles.find((permission) => HamPermissions.administrator.includes(permission));
    let isAlive = true;
    hamService
      .getInterviewerById(id)
      .then((res) => ({
        ...res,
        isAdmin: !!role,
      }))
      .then((result) => (isAlive ? setData(result) : null));
    return () => (isAlive = false);
  }, [id, user.roles]);

  return data ? (
    <WrapperPage classes={'container'}>
      <Tabs defaultActiveKey="areas" id="uncontrolled-tab-example">
        <Tab eventKey="areas" title="Áreas">
          <div className="card card-table mb-4">
            <CheckAreas data={data} />
          </div>
        </Tab>
        <Tab eventKey="preferencias" title="Preferencias">
          <div className="card card-table mb-4">
            <FormPreferences data={data} />
          </div>
        </Tab>
      </Tabs>
    </WrapperPage>
  ) : null;
};

export { InterviewerEdit };
