import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../Shared';
import { hamService, notificationService } from '../../../../../services';

export const DeleteInterviewer = ({ interviewer, closeModal }) => {

  const onDeleteInterviewer = () =>
    hamService
      .deleteInterviewer(interviewer.pslId)
      .then(() => {
        notificationService.showSuccess(
          'Borrado exitoso',
          `El entrevistador '${interviewer.name}' fue borrado con éxito`,
        );
        closeModal(true);
      })
      .catch(() => notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado'));

  const modalButtons = [
    { label: 'Cancelar', onclick: () => closeModal(false), auxClass: 'btn-secondary' },
    { label: 'Borrar', onclick: onDeleteInterviewer, auxClass: 'btn-primary' },
  ];

  return (
    <Modal
      show={true}
      title="Eliminar entrevistador"
      buttons={modalButtons}
      onClose={() => closeModal(false)}
    >
      <p>Recuerde que esta opción no puede deshacerse</p>
    </Modal>
  );
};

DeleteInterviewer.propTypes = {
  interviewer: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
