import React from 'react';
import PropTypes from 'prop-types';

export const InputList = ({ options = [], className = '', showList = false, ...props }) => (
  <>
    <input type="text" list="inputListOptions" className={`form-control ${className}`} {...props} />
    {showList ? (
      <datalist id="inputListOptions">
        {options.map((item) => (
          <option key={item} value={item}></option>
        ))}
      </datalist>
    ) : null}
  </>
);

InputList.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  showList: PropTypes.bool,
};
