import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { Modal, Icon } from '../../Shared';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const PrivateNotes = ({ notes }) => {
  const [open, setOpen] = useState(false);
  const modalButtons = [
    { label: 'Cerrar', onclick: () => setOpen(false), auxClass: 'btn-primary' },
  ];
  return (
    <>
    {notes !== '' && <>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-reschedule'}>
            Notas
          </Tooltip>
        }
      >
        <Icon style={{height: '1em'}} icon={'comment'} onClick={() => setOpen(true)} disabled={false} />
      </OverlayTrigger>
      <Modal show={open} title="Notas privadas" buttons={modalButtons} onClose={() => setOpen(false)}>
        <p>{notes}</p>
      </Modal></>}
    </>
  );
};

PrivateNotes.propTypes = {
  notes: Proptypes.string.isRequired,
};