import environments from '../environments';
import { request } from './api.service';

function authorizedRequest(
  url,
  { method = 'GET', body = undefined, showLoading = true } = {},
  environment = environments.hamUrl,
) {
  return request(`${environment}${url}`, {
    body,
    method,
    showLoading,
  });
}

// Get Evaluators
const getAssessmentRecord = ({ evaluated, skill }) =>
  authorizedRequest(`/api/v1/historic/${evaluated}/${skill}`);

// Get Evaluators
const getEvaluators = () => authorizedRequest('users', {}, environments.users_rest);

// Get form
const getForm = (formId) =>
  authorizedRequest(`evaluations/questions/forms/${formId}`, {}, environments.evaluations);

// Get single skill
const getSkill = (skillId) =>
  authorizedRequest(
    `evaluations/questions/skills`,
    { method: 'POST', body: { skillId } },
    environments.evaluations,
  );

// Post Skill
const postSkillsInterview = (body) =>
  authorizedRequest(`evaluations/save`, { body, method: 'POST' }, environments.evaluations);

export const employeeService = {
  getAssessmentRecord,
  getEvaluators,
  getForm,
  getSkill,
  postSkillsInterview
};
