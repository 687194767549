import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {DropdownUI, ModalLeft } from '../../../../Shared';
import { Form } from 'react-bootstrap';
import { dateToTime, onCreateInterAppointment } from '.';
import { employeeService, notificationService } from '../../../../../../../services';
import environments from '../../../../../../../environments';
import { SchedulingContext } from '../../../context';
import { useHistory } from 'react-router-dom';

const CreateInterAppointment = ({ data, closeModal, employeeAppointment = null }) => {
  const { searchCriteria } = useContext(SchedulingContext);
  const history = useHistory();
  const title = `Agendar reunión (${data.interviewer.email})`;
  const start = dateToTime(data.start);
  const end = dateToTime(data.end);

  const [isLoading, setIsLoading] = useState(true);
  const [employeesList, setEmployeesList] = useState([]);
  const [description, setDescription] = useState('');
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    const getAllEmployees = async() => {
      try {
        // Get employees
        let res = await employeeService.getEvaluators();
        res = res.filter(
          (item) => item.jauthId !== data.interviewer.pslId).map(
            (item) => ({ ...item, img: `${environments.imgUrl}${item.ldapId}.jpg` }));
        setEmployeesList(res);
        setDescription('Description....');
      } catch (error) {
        notificationService.showWarning('Ooops!', `Ocurrió un error inesperado ${error}`);
      } finally {
        setIsLoading(false);
      }
    }
    getAllEmployees();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || isLoading) {
    return null;
  }

  return (
    <ModalLeft
      title={title}
      isOpen={true}
      closeModal={closeModal}
      submit={onCreateInterAppointment({ event: data, employee, searchCriteria }, {history, employeeAppointment})}
      titleButton="Agendar"
    >
      <Form.Group className="required form" controlId="start">
        <Form.Label>Inicio ({data.start.toLocaleDateString()})</Form.Label>
        <Form.Control type="time" required name="start" defaultValue={start} />
      </Form.Group>
      <Form.Group className="required" controlId="end">
        <Form.Label>Fin ({data.end.toLocaleDateString()})</Form.Label>
        <Form.Control type="time" required name="end" defaultValue={end} />
      </Form.Group>
      <Form.Group controlId="employeeInfo">
        <Form.Label>Nombre</Form.Label>
        <DropdownUI
          isImg={true}
          isOneRegister={true}
          list={employeesList}
          valueFilter="name"
          selections={({ detail }) => setEmployee(detail[0])}
        />
      </Form.Group>
      <Form.Group className="required" controlId="description">
        <Form.Label>Descripción entrevista</Form.Label>
        <Form.Control as="textarea" required name="description" rows={13} defaultValue={description} />
      </Form.Group>
      <Form.Group controlId="attendees">
        <Form.Label>Invitados adicionales</Form.Label>
        <Form.Control type="text" name="attendees" />
        <Form.Text className="text-muted">
          Ingrese el correo de los invitados separados por coma (,)
        </Form.Text>
      </Form.Group>
    </ModalLeft>
  );
}

CreateInterAppointment.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.any,
  isVisible: PropTypes.bool,
  employeeAppointment: PropTypes.object,
};

export default CreateInterAppointment;