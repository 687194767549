import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import './Error.scss';

import logo from '../../assets/images/tam-logo.svg';
import warning from '../../assets/icons/warning.svg';
import AuthenticationConnectorInstance from '../../adapters/connectors/authentication.connector';

const titles = {
  401: '401 Token invalido',
  403: '403 Sin permisos',
};
const messages = {
  401: 'Ups, no tenemos acceso a tu información. Inicia sesión nuevamente.',
  403: 'Actualmente no tienes acceso a la aplicación',
};

const Error = () => {
  const history = useHistory();
  let { code } = useParams();
  const title = titles[code];
  const message = messages[code];
  const staffingLink = 'mailto: colombiastaffing@perficient.com';

  const onLogout = () => {
    AuthenticationConnectorInstance.clear();
    history.push('/');
  };

  return (
    <div className="error">
      <header className="error-header">
        <img src={logo} alt={title} className="ml-3" height="50" />
      </header>
      <section className="error-section">
        <div className="error-section-box">
          <img alt={title} className="material-icons error-section-box__icon mb-5" src={warning} />
          <div className="error-section-box-texts">
            <div className="error-section-box-texts__title mb-5">{message}</div>
            <p className="error-section-box-texts__desc">
              Comunícate con&nbsp;
              <a href={staffingLink} className="error-section-box-texts__bold">
                Staffing
              </a>
            </p>
            <Link to={'/'} className="error-section-box-texts__bold" onClick={onLogout}>
              Regresar al login
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
export { Error };
