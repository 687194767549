import React from 'react';
import PropTypes from 'prop-types';

import { MegaDropdown } from './';

export const MegaMenu = ({ data }) => (
  <ul className="d-flex h-100 list-unstyled m-0 ml-5">
    {data.map(({ button, dropdown }, i) => (
      <li key={i} className="position-relative megamenu">
        <span className="d-flex h-100 align-items-center px-4 text-nowrap">{button}</span>
        <MegaDropdown data={dropdown} />
      </li>
    ))}
  </ul>
);

MegaMenu.propTypes = {
  data: PropTypes.array,
};
