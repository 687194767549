import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import { Select } from '../../Shared/HookForm';
import { Button } from '../../Shared';
// services
import { schema, setDefaultValues, states } from './utils';
import { queryURL, useQuery } from '../../../../../utils';
// contex
import { AreasContext } from '../../../../../context';
// styles
import '../Interviews.scss';

export const FindInterview = ({ filter, setFilter }) => {
  const { areas } = useContext(AreasContext);
  const [areasOptions, setAreasOptions] = useState([]);
  const [areaSelect, setAreaSelect] = useState({ interviewTypes: [] });
  const [typeOptions, setTypeOptions] = useState([]);
  // set page filter
  const history = useHistory();
  const { ...rest } = useQuery();
  // form configuration
  const { register, handleSubmit, reset, control, formState, errors } = useForm({
    mode: 'onBlur',
    defaultValues: setDefaultValues(filter),
    resolver: yupResolver(schema),
  });
  const { isDirty } = formState;
  const formRef = useRef(null);

  // Click submit
  const handleEditSave = () => isDirty && formRef.current.dispatchEvent(new Event('submit'));
  // Click submit
  const handleChangeArea = ({ target }) =>
    target.value && setAreaSelect(areas.find((area) => area.code === target.value));

  useEffect(() => {
    if (areas) {
      const castAreas = areas.map((area) => ({ ...area, label: area.name, value: area.code }));
      setAreasOptions([{ label: '', value: '' }, ...castAreas]);
    }
  }, [areas]);

  useEffect(() => {
    const type = areaSelect.interviewTypes.map((interviewerType) => ({
      ...interviewerType,
      label: interviewerType.name,
      value: interviewerType.code,
    }));
    setTypeOptions([{ label: '', value: '' }, ...type]);
  }, [areaSelect]);

  const onSubmit = (formData) => {
    const query = queryURL({ ...rest, page: 1 });
    history.push(query);
    const state =
      Number.isInteger(parseInt(formData.state)) || parseInt(formData.state, 10) === 0
        ? parseInt(formData.state, 10)
        : undefined;
    setFilter({
      ...formData,
      state: state,
      endDate: formData.endDate ? moment(formData.endDate).endOf('days').toISOString() : undefined,
      startDate: formData.startDate ? moment(formData.startDate).startOf('days').toISOString() : undefined,
    });
  };

  const clearFilter = () => {
    reset({
      state: '',
      interviewType: '',
      startDate: '',
      endDate: '',
      area: ''
    });
    setFilter({});
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="row" noValidate>
      <div className="form col">
        <Form.Group controlId="state">
          <Select
            name="area"
            label="Área"
            onChange={(e) => handleChangeArea(e)}
            options={areasOptions}
            register={register}
            error={errors.state}
          />
        </Form.Group>
      </div>
      <Form.Group className="form col" controlId="state">
        <Select name="state" label="Estado" options={states} register={register} error={errors.state} />
      </Form.Group>
      <div className="form col">
        <Select
          name="interviewType"
          label="Tipo"
          options={typeOptions}
          register={register}
          error={errors.state}
        />
      </div>
      <Form.Group className="form col" controlId="startDate">
        <Form.Label>F.desde</Form.Label>
        <Controller
          name="startDate"
          control={control}
          render={({ onChange }) => <Form.Control type="date" name="startDate" onChange={onChange} ref={register} />}
          error={errors.startDate}
        />
      </Form.Group>
      <Form.Group className="form col" controlId="endDate">
        <Form.Label>F.hasta</Form.Label>
        <Controller
          name="endDate"
          control={control}
          render={({ onChange }) => <Form.Control type="date" name="endDate" onChange={onChange} ref={register} />}
          error={errors.endDate}
        />
      </Form.Group>
      <div className="find-interview-btn">
        <Button type="submit" onClick={() => handleEditSave()} className="col-12 mt-3">
          Buscar
        </Button>
      </div>
      <div className="find-interview-btn">
        <Button type="reset" onClick={clearFilter} className="col-12 btn-secondary mt-3 ">
          Limpiar
        </Button>
      </div>
    </form>
  );
};

FindInterview.propTypes = {
  filter: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    state: PropTypes.number,
    interviewType: PropTypes.string,
  }),
  setFilter: PropTypes.func,
};
