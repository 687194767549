import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Modal as ReactModal } from 'react-bootstrap';

const modalRoot = document.getElementById('modal-root');

// Modal V2
export const Modal = ({ show, title, onClose, children, buttons = [] }) => {
  const modal = (
    <ReactModal show={show} onHide={onClose}>
      <ReactModal.Header closeButton>
        <ReactModal.Title>{title}</ReactModal.Title>
      </ReactModal.Header>
      <ReactModal.Body>{children}</ReactModal.Body>
      <ReactModal.Footer className="justify-content-center">
        {buttons.length > 0 &&
          buttons.map((button, i) => (
            <button
              key={i}
              className={['btn', button.auxClass,].join(' ')}
              onClick={button.onclick}
              disabled={button.disabled}
            >
              {button.label}
            </button>
          ))}
      </ReactModal.Footer>
    </ReactModal>
  );

  return ReactDOM.createPortal(modal, modalRoot);
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onclick: PropTypes.func.isRequired,
      auxClass: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
};
