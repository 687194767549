import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Select, Button } from '../../Shared';

export const TablePositions = ({ options, areas, onAddArea, onDeleteArea, onEditArea }) => {
  const [selectedArea, setSelectedArea] = useState('');
  const [openPositions, setOpenPositions] = useState(0);
  const [editFlag, setEditFlag] = useState([]);

  const consultIndex = (index) => editFlag.indexOf(index);

  const addArea = () => {
    const done = onAddArea(selectedArea, openPositions);
    if (done) {
      setSelectedArea('');
      setOpenPositions(0);
    }
  };

  const editArea = (indexArea) => {
    const indexFlag = consultIndex(indexArea);
    const arr = [...editFlag];
    if (indexFlag >= 0) {
      arr.splice(indexFlag, 1);
    } else {
      arr.push(indexArea);
    }

    setEditFlag(arr);
  };

  return (
    <table className="table mt-0">
      <thead>
        <tr>
          <th>Áreas</th>
          <th className="text-nowrap">Número de posiciones abiertas</th>
          <th className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {areas.map((area, i) => (
          <tr key={area.code}>
            <td className="text-nowrap">{area.name}</td>
            <td>
              {consultIndex(i) >= 0 ? (
                <input
                  type="number"
                  className="form-control w-50 bg-light"
                  value={area.openPositions}
                  onChange={(e) => onEditArea(parseInt(e.target.value), i)}
                  min={0}
                />
              ) : (
                area.openPositions
              )}
            </td>
            <td className="text-center">
              <Icon icon="pen mr-3" onClick={() => editArea(i)} />
              <Icon icon="trash" onClick={() => onDeleteArea(i)} />
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <Select
              name="selectedArea"
              options={options}
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              placeholder="Seleccione"
              className="bg-light custom-select"
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control w-50 bg-light"
              value={openPositions}
              onChange={(e) => setOpenPositions(parseInt(e.target.value))}
            />
          </td>
          <td className="text-center">
            <Button
              variant="btn btn-secondary"
              className="m-auto"
              onClick={addArea}
              disabled={!selectedArea || openPositions < 1}
            >
              Agregar
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

TablePositions.propTypes = {
  options: PropTypes.array,
  areas: PropTypes.array,
  onAddArea: PropTypes.func,
  onDeleteArea: PropTypes.func,
  onEditArea: PropTypes.func,
};
