import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { employeeService, hamService, notificationService } from '../../../../../../services';

import { DropdownUI, ModalUI } from '../../../Shared';

import { setDefaultValues, validateError, schema } from './utils';

import environments from '../../../../../../environments';

const CreateInterviewer = ({ showForm, closeModal, onCreated }) => {
  const [employees, setEmployees] = useState([]);
  const { handleSubmit, errors, control, formState } = useForm({
    mode: 'onSubmit',
    defaultValues: setDefaultValues(),
    resolver: yupResolver(schema),
  });
  const { isDirty } = formState;
  const formRef = useRef(null);

  useEffect(() => {
    employeeService
      .getEvaluators()
      .then((res) => res.map((item) => ({ ...item, img: `${environments.imgUrl}${item.ldapId}.jpg` })))
      .then(setEmployees)
      .catch((error) => {
        console.error(error);
        notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado');
      });
  }, []);

  // Click submit
  const handleEditSave = () => isDirty && formRef.current.dispatchEvent(new Event('submit'));

  const onSubmit = ({pslEmail}) => {
    hamService
      .putInterviewer({ jauthId: pslEmail[0].jauthId })
      .then((result) => {
        if (result && result.error) {
          validateError(result.error);
        } else {
          notificationService.showSuccess('Registro exitoso', 'Entrevistador guardado con exito');
          onCreated();
          closeModal(false);
          return true;
        }
      })
      .catch(validateError);
  };

  const modalButtons = [
    { label: 'Cancelar', variant: 'btn btn-secondary', onClick: () => closeModal(false) },
    { label: 'Añadir', type: 'submit', onClick: () => handleEditSave() },
  ];

  return showForm ? (
    <ModalUI
      title="Añadir entrevistador"
      onClose={closeModal}
      position="right"
      className="right-fold-modal d-flex flex-column position-fixed vh-100 bg-white"
      buttons={modalButtons}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="form">
        <Form.Group controlId="pslEmail">
          <Form.Label>Entrevistador</Form.Label>
          <Controller
            name="pslEmail"
            control={control}
            render={({ onChange }) => (
              <DropdownUI
                isImg={true}
                isOneRegister={true}
                list={employees}
                valueFilter="name"
                selections={({ detail }) => onChange(detail)}
              />
            )}
            error={errors.pslEmail}
          />
        </Form.Group>
      </form>
    </ModalUI>
  ) : null;
};

CreateInterviewer.propTypes = {
  closeModal: PropTypes.func,
  showForm: PropTypes.bool,
  onCreated: PropTypes.func,
};

export { CreateInterviewer };
