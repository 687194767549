import React, { useState, useContext, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Form } from 'react-bootstrap';

import {
  formatDate,
  formatHour,
  onUpdateAppointment,
} from './shared';

import { SchedulingContext } from '../../../context';
import { employeeService, notificationService } from '../../../../../../../services';
import { DropdownUI, ModalLeft } from '../../../../Shared';
import environments from '../../../../../../../environments';

export const UpdateInterAppointment = ({ interview, closeModal, data }) => {
  const { searchCriteria } = useContext(SchedulingContext);
  const [description, setDescription] = useState('');
  const startDate = formatDate(data.start);
  const endDate = formatDate(data.end);
  const [isLoading, setIsLoading] = useState(true);
  const [employeesList, setEmployeesList] = useState([]);
  const [employee, setEmployee] = useState({ 
    ...interview.employee,
    email: interview.employee.code
  });

  useEffect(() => {
    const getAllEmployees = async() => {
      try {
        // Get employees
        let res = await employeeService.getEvaluators();
        res = res.map((item) => ({ ...item, img: `${environments.imgUrl}${item.ldapId}.jpg` }));
        setEmployeesList(res);
        setDescription(interview.description || 'Description...');
      } catch (error) {
        notificationService.showWarning('Ooops!', `Ocurrió un error inesperado ${error}`);
      } finally {
        setIsLoading(false);
      }
    }
    if(!isLoading) return;
    getAllEmployees();
    return () => {
      setIsLoading(false);
    };
  }, [interview, isLoading]);

  if (!interview || isLoading) {
    return null;
  }

  return (
    <ModalLeft
      title={`Reprogramar reunión ${interview.interviewer.code}`}
      isOpen={true}
      closeModal={closeModal}
      submit={onUpdateAppointment(interview, employee, searchCriteria, data)}
      titleButton="Agendar"
    >
      <Form.Group className="required form" controlId="start">
        <Form.Label>Inicio ({startDate})</Form.Label>
        <Form.Control type={'time'} required name={'startDate'} defaultValue={formatHour(data.start)} />
      </Form.Group>
      <Form.Group className="required" controlId="end">
        <Form.Label>Fin ({endDate})</Form.Label>
        <Form.Control type={'time'} required name={'endDate'} defaultValue={formatHour(data.end)} />
      </Form.Group>
      <Form.Group controlId="employeeInfo">
        <DropdownUI
          isImg={true}
          isOneRegister={true}
          list={employeesList}
          valueFilter="name"
          data={employee && employee.jauthId ? 
            [employeesList.find((el) => el.jauthId === employee.jauthId)] : []}
          selections={({ detail }) => setEmployee(detail[0])}
        />
      </Form.Group>
      <Form.Group className="required form" controlId={'description'}>
        <Form.Label>Descripción entrevista</Form.Label>
        <Form.Control as={'textarea'} required name={'description'} rows={15} defaultValue={description} />
      </Form.Group>
      <Form.Group controlId="attendees">
        <Form.Label>Invitados adicionales</Form.Label>
        <Form.Control type="text" name="attendees" defaultValue={interview.attendees.filter(Boolean).join()} />
        <Form.Text className="text-muted">
          Ingrese el correo de los invitados separados por coma (,)
        </Form.Text>
      </Form.Group>
    </ModalLeft>
  );
};

UpdateInterAppointment.propTypes = {
  interview: Proptypes.shape({
    _id: Proptypes.string,
    startDate: Proptypes.string,
    endDate: Proptypes.string,
    type: Proptypes.shape({
      code: Proptypes.string,
    }),
    interviewer: Proptypes.shape({
      code: Proptypes.string,
    }),
    employee: Proptypes.shape({
      code: Proptypes.string,
      name: Proptypes.string,
      jauthId: Proptypes.string
    }),
    description: Proptypes.string,
    privateNotes: Proptypes.string,
    isTechnical: Proptypes.bool,
    isPsychometric: Proptypes.bool,
    attendees: Proptypes.array,
    appointmentId: Proptypes.string,
    interviewerPslId: Proptypes.string,
    addCv: Proptypes.bool,
  }),
  closeModal: Proptypes.func,
  data: Proptypes.shape({
    start: Proptypes.any,
    end: Proptypes.any,
    interviewer: Proptypes.shape({
      created_at: Proptypes.string,
      email: Proptypes.string,
      name: Proptypes.string,
      pslId: Proptypes.string,
      updatedAt: Proptypes.string,
      _v: Proptypes.number,
      _id: Proptypes.string,
    }),
  }),
  closeCalendar: Proptypes.func,
};
