import PropTypes from 'prop-types';
import React from 'react'

const lightsValidation = (id, data) => {
  let availables;
  let requires;

  if (id === 'profilesAvailable') {
    availables = data.profilesAvailable;
    requires = data.profilesRequired;
  } else if (id === 'technicalAvailable') {
    availables = data.technicalAvailable;
    requires = data.technicalRequired;
  } else {
    return;
  }
  return { availables, requires };
}

const lightsColor = (availables, requires) => {
  const requiresPlus = requires * 1.5;
  let color = 'red';
  
  if (availables > requiresPlus) {
    color = 'green';
  } else if (requires <= availables && availables <= requiresPlus) {
    color = 'yellow';
  }
  return color;
}

const LightsComponent = (id, data) => {
  const response = lightsValidation(id, data);
  if (!response) return;

  return (
    <span
      className={`circle-lights ${lightsColor(response.availables, response.requires)}`}
    />
  );
}

LightsComponent.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export { LightsComponent };
