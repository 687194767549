import moment from 'moment';

export const TODAY = moment().format('YYYY-MM-DD');
export const COMMENT_MIN_LENGTH = 10;
export const NAME_MIN_LENGTH = 5;
export const NAV_WIDTH = 240;

export const DEFAULT_OPT = 'No valorado';
export const NO_INFO = 'Sin información';

export const ERROR = {
  EVALUATOR: 'Nombre de Evaluador inválido (mínimo 5 caracteres)',
  DATE: 'Fecha inválida',
  SKILLS: 'Hay 1 o más valoraciones incompletas',
  EMPTY: 'No se ha valorado ninguna competencia',
};

export const SKILLS = [
  {
    label: 'Muy Alto',
    value: 5,
  },
  {
    label: 'Alto',
    value: 4,
  },
  {
    label: 'Medio',
    value: 3,
  },
  {
    label: 'Bajo',
    value: 2,
  },
  {
    label: 'Muy bajo',
    value: 1,
  },
  {
    label: 'No valorado',
    value: 0,
  },
];

export const assessmentType = {
  PEER: 'peer',
  SELF: 'self',
  EXPERT: 'expert',
  EXTERNAL: 'external'
};
