import React from 'react';
import PropTypes from 'prop-types';
import { LightsComponent } from './';

export const TableColumn = ({ rowValue, headValue, row, className}) => (
  <td
    className={className}
  >
    <span className="text-cell">{rowValue}</span>
    {LightsComponent(headValue, row)}
  </td>
);

TableColumn.propTypes = {
  row: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
    actions: PropTypes.any,
  }),
  rowValue: PropTypes.any,
  headValue: PropTypes.any,
  className: PropTypes.string,
};
