import { Admin, Error, Login, LoginCallback } from './components';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { UserProvider } from './context';
import AuthenticationConnectorInstance from './adapters/connectors/authentication.connector';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/error/:code" component={Error} />

        <UnauthenticatedRoute exact path={['/', '/login']} component={Login} />

        <UserProvider>
          <Route path="/callback" component={LoginCallback} />
          <PrivateRoute path="/admin" component={Admin} />
        </UserProvider>
      </Switch>
    </BrowserRouter>
  );
};

const PrivateRoute = ({ component: Component, ...options }) => {
  const token = AuthenticationConnectorInstance.getToken();
  return <Route {...options} render={(props) => (token ? <Component {...props} /> : <Redirect to="/" />)} />;
};

const UnauthenticatedRoute = ({ component: Component, ...options }) => {
  const token = AuthenticationConnectorInstance.getToken();
  return (
    <Route {...options} render={(props) => (!token ? <Component {...props} /> : <Redirect to="/admin" />)} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.any,
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.any,
  restricted: PropTypes.bool,
  user: PropTypes.any,
};

export default Routes;
