import React from 'react';
import PropTypes from 'prop-types';

export const TableHead = ({thead, onSelect}) => (
  <tr>
    {onSelect ? <th></th> : null}
    {thead.map((value, index) => (
      <th scope="col" key={index} className={value.className}>
        {value.name}
      </th>
    ))}
  </tr>
);

TableHead.propTypes = {
  thead: PropTypes.array,
  onSelect: PropTypes.func,
};