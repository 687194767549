import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { useUserContext } from '../../../../context';

import { hamService, notificationService } from '../../../../services';

import { Table } from '../Shared';
import { formatMyInterviewData, tHead } from './utils';

const MyInterviews = () => {

  const [myInterviews, setMyInterviews] = useState([]);
  const { user } = useUserContext();

  useEffect(() => {
    async function prepareInterviews() {
      try {
        const interviews = await hamService.getMyInterview(user);
        setMyInterviews(interviews.map(formatMyInterviewData));
      } catch (error) {
        notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado');
      }
    }
    prepareInterviews();
  }, [user]);

  return (
    <div className="p-5">
      <div className="mb-5">
        {!myInterviews || myInterviews.length < 1 ? (
          <Alert variant="warning" className="mt-3">
            No se encuentran entrevistas
          </Alert>
        ) : (
          <Table thead={tHead} data={myInterviews} />
        )}
      </div>
    </div>
  );
};

export { MyInterviews };
