import { notificationService } from '../../../../../../services';
import * as yup from 'yup';

export const validateError = (error) => {
  switch (error) {
    case 'duplicated-key':
      notificationService.showWarning('Registro existente', 'Entrevistador ya se encuentra registrado');
      break;
    case 'employee-not-found':
      notificationService.showWarning(
        'Empleado no existe',
        'Empleado no se encuentra registrado en la base de datos',
      );
      break;
    default:
      notificationService.showWarning('Ha ocurrido un error', 'Intentelo de nuevo o comuniquese con soporte');
      break;
  }
};

// format defaultValues
export const setDefaultValues = () => ({
  pslEmail: '',
});

export const schema = yup.object().shape({
  pslEmail: yup.array().required(),
});
