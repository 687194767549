import React from 'react';
import PropTypes from 'prop-types';

import PageItem from './PageItem';

export const Pagination = ({
  totalItems = 0,
  pageSize = 10,
  currentPage = 1,
  onClickPage
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  let pages = Array.from({length: totalPages}, (_, i) => i + 1);

  if (!pages.length) return null;
  return (
    <ul className="pagination">
      <PageItem label="&#10094;&#10094;" onClick={() => onClickPage(1)} disabled={currentPage === 1} />
      <PageItem label="&#10094;" onClick={() => onClickPage(currentPage - 1)} disabled={currentPage === 1} />
      {pages.map((page, key) => (
        <PageItem
          key={key}
          label={page.toString()}
          onClick={() => onClickPage(page)}
          active={currentPage === page}
        />
      ))}
      <PageItem
        label="&#10095;"
        onClick={() => onClickPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <PageItem
        label="&#10095;&#10095;"
        onClick={() => onClickPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    </ul>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onClickPage: PropTypes.func,
  pageNeighbours: PropTypes.number,
};
