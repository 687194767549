import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const Bookmark = ({ label, to, i, canDelete, onDelete, isActive = false }) => (
  <li className={['d-inline-flex align-items-center px-4 bookmark', isActive ? 'active' : null].join(' ')}>
    <Link to={to} className="py-4 text-decoration-none">
      {label}
    </Link>
    {canDelete ? (
      <i className="icon-close-1 ml-3" onClick={() => onDelete(i)}>
      </i>
    ) : null}
  </li>
);

Bookmark.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  i: PropTypes.number,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  isActive: PropTypes.bool,
};
