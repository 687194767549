import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({
  label,
  prepend = null,
  register = null,
  error = null,
  className = '',
  onClick = null,
  classNamePrepend = 'text-muted mr-1',
  ...inputProps
}) => (
  <div className={['form-group', inputProps.required ? 'required' : null].join(' ')}>
    {label && <label className="form-label">{label}</label>}
    <div className={`d-flex align-items-center ${className}`} onClick={onClick}>
      {prepend && (
        <div className="input-group-prepend">
          <span className={`${classNamePrepend}`}>{prepend}</span>{' '}
        </div>
      )}
      <input
        className={['form-control', error ? 'is-invalid' : null].join(' ')}
        ref={register}
        maxLength={150}
        {...inputProps}
      />
    </div>
    {error && <span className="text-danger small">{error.message}</span>}
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prepend: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.any,
  classNamePrepend: PropTypes.string,
};
