import PropTypes from 'prop-types';
import React from 'react';
import { Pagination, Select } from '..';
import { useHistory } from 'react-router-dom';
import { useQuery, queryURL, generateOptionsFromNumberArray } from '../../../../../utils';

export const BottomBar = ({ totalItems }) => {
  const history = useHistory();
  const { page, pageSize, ...rest } = useQuery();

  const first = (page - 1) * pageSize + 1;
  const last = page * pageSize < totalItems ? page * pageSize : totalItems;
  const opts = generateOptionsFromNumberArray([5, 10, 20, 50]);

  const onChangePage = (page) => {
    const query = queryURL({ page, pageSize, ...rest });
    history.push(query);
  };

  const onChangePageSize = (pageSize) => {
    const query = queryURL({ page: 1, pageSize, ...rest });
    history.push(query);
  };

  if (totalItems < 1) return null;

  return (
    <div className="d-flex align-items-center">
      <span className="mr-3">
        Mostrando {first} a {last} de {totalItems}
      </span>
      <span>
        <Select
          name="pageSize"
          options={opts}
          value={pageSize}
          onChange={(e) => onChangePageSize(e.target.value)}
        />
      </span>
      <span className="ml-3 mr-auto">resultados por página</span>
      <Pagination totalItems={totalItems} pageSize={pageSize} currentPage={page} onClickPage={onChangePage} />
    </div>
  );
};

BottomBar.propTypes = {
  totalItems: PropTypes.number.isRequired,
};
