import {
  getForms,
  employeeService,
  notificationService,
  getSkills,
} from '../../../../../services';

import { COMMENT_MIN_LENGTH, DEFAULT_OPT, TODAY, assessmentType } from './constants';
import moment from 'moment';

// Deep clone for internal state
const deepCloneObj = (arr) => JSON.parse(JSON.stringify(arr));

/**
 * Initialize form data values
 * @param {*} data array[]
 */
export const initializeData = (data) => {
  const newData = deepCloneObj(data);
  return newData.map((skill) => {
    skill.level = 0;
    skill.disabled = true;
    skill.options = [...skill.questions.options, DEFAULT_OPT];
    skill.details = skill.questions.details.map((item) => {
      return {
        detail: item,
        answer: DEFAULT_OPT,
      };
    });
    skill.comments = '';
    skill.commentsRequired = false;
    return skill;
  });
};

/**
 * Update check/comments and validate disable/required fields
 * @param {*} data
 * @param {*} setData
 */
export const updateValue = (data, setData) => (e) => {
  const { name, value } = e.target;
  const [skillId, type, index = null] = name.split('---');
  const newData = [...data];
  const skill = newData.filter((item) => item.skillId === skillId)[0];
  if (type === 'details') {
    skill.details[index].answer = value;
  } else {
    skill[type] = value;
  }
  skill.disabled = skill.level > 0 ? false : true;
  skill.commentsRequired = !skill.disabled && skill.comments.replace(/ /g, '').length < COMMENT_MIN_LENGTH;
  setData(newData);
};

/**
 * Generate sidebar links
 * @param {*} skills array of questions
 */
export const createNavItems = (skills) =>
  skills.map((skill) => ({
    id: skill.skillId,
    label: skill.displayName ? skill.displayName : skill.skillId,
  }));

/**
 * Calculate colSpan
 * @param {*} a Number of skill options
 * @param {*} b Number of question options
 */
export const calcColSpan = (a, b) => {
  let colSpan = 1;
  colSpan += a > b ? a - b : 0;
  return colSpan;
};

/**
 * Set max date to TODAY
 * @param {*} value
 */
export const validateDate = (value) => (moment().isSameOrAfter(value) ? value : TODAY);

/**
 * @description: call all services needs to component
 * @param {*} id:
 * @param {*} template
 */
export const getAPIData = async (id, template) => {
  const [evaluatorsRes, form, forms, skills] = await Promise.all([
    employeeService.getEvaluators(),
    employeeService.getForm(template),
    getForms(),
    getSkills(),
  ]);
  const employee = evaluatorsRes.find(({ jauthId }) => jauthId === id);
  const evaluators = evaluatorsRes.map((item) => item.name);
  if (!Array.isArray(form)) {
    notificationService.showWarning('Ooops', 'Ha ocurrido un error');
    return;
  }
  return [evaluators, employee, form, forms, skills];
};

/**
 * @description: find skill to current assessment
 * @param {*} forms: list all forms
 * @param {*} form: id form the evaluation
 */
export const searchForm = (forms, form) =>
  forms.find((data) => data.formId === form) || { formId: form, name: 'Competencia no encontrada' };

/**
 * @description: transform data to ui-component to structure EndPoint
 * @param {*} setSaveEvaluation: function save object
 */
export const sendData =
  (setSaveEvaluation) =>
  ({  employeeEmail }) =>
  (e) => {
    const data = createSkillsObj(e.detail);
    if (data.error) {
      return notificationService.showWarning('Alerta', data.error);
    }
    const assessment = {
      ...data,
      evaluated: employeeEmail
    };
    setSaveEvaluation(assessment);
  };

/**
 * Format the data to send
 * @param {*} formData
 */
export const createSkillsObj = (formData) => {
  if (formData.error) {
    return formData;
  }
  let skills = formData.success.reduce((accSkill, currSkill)=>
    parseInt(currSkill.level,10) ? [
      ...accSkill, {
        skillId: currSkill.skillId,
        level: parseInt(currSkill.level, 10),        
        observations: currSkill.comments,
        questions: questionsDetails(currSkill.details) 
      }
    ] : accSkill
  ,[]);

  return { 
    kind: assessmentType.EXPERT,
    skills
  };
};

const questionsDetails = (details) => (
  details.reduce((accDetail,currDetail)=> 
    parseInt(currDetail.answer) ? [
      ...accDetail,{
        detail: currDetail.detail, 
        answer: currDetail.answer
      }
    ] : accDetail
  ,[])
);
