import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { loaderService } from '../../../services';

const Loader = () => {
  const [isVisible, setIsVisible] = useState(false);
  loaderService.subscribe(setIsVisible);

  return isVisible
    ? createPortal(
        <div className="modal-backdrop show d-flex justify-content-center align-items-center top-z">
          <div className="spinner-border text-white" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        </div>,
        document.querySelector('body'),
      )
    : null;
};

export { Loader };
