/**
 * Transform areas to tree data
 * Set a unique code in each element as required in component to determine checked/expanded items
 * @param {*} areas: area to interview
 */
export const areasToTree = (areas) =>
  areas.map((area) => ({
    value: area.code,
    label: area.name,
    children: area.interviewTypes.map((interviewType) => ({
      value: `${area.code}/${interviewType.code}`,
      label: interviewType.name,
      children: interviewType.subCategories.map((subCategories) => ({
        value: `${area.code}/${interviewType.code}/${subCategories.code}`,
        label: subCategories.name,
      })),
    })),
  }));

/**
 * Check user areas in Tree
 * Create an array of unique ids to check in Tree component
 * @param {*} obj
 */
export const findCheckedAreas = (obj) => {
  let checked = [];
  Object.entries(obj).map(([keyArea, areas]) => {
    return Object.entries(areas).map(([keyType, types]) => {
      return types.map((level) => checked.push(`${keyArea}/${keyType}/${level}`));
    });
  });
  return checked;
};

// obj.hasOwnProperty() causes linter error, so...
export const hasProperty = (obj, propName) => Object.prototype.hasOwnProperty.call(obj, propName);

/**
 * Selected values into object
 * Transform checked array data into an API format object
 * @param {*} array
 */
export const checkedToObj = (array) =>
  array.reduce((obj, item) => {
    const [area, type, level] = item.split('/');
    if (!obj[area]) {
      obj[area] = {};
    }
    if (!obj[area][type]) {
      obj[area][type] = [];
    }
    obj[area][type].push(level);
    return obj;
  }, {});
