import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// services
import { employeeService, getForms, hamService, notificationService } from '../../../../../../services';
// context
import { AreasContext } from '../../../../../../context';
// components
import { Button, DropdownUI, ModalUI } from '../../../Shared';
import { Input } from '../../../Shared/HookForm';
import { InterviewType } from '../InterviewType';
// utils
import { setDefaultValues, schema, castInterviewType, validateLeaders, validateError } from './utils';
//styles
import './EditArea.scss';
import environments from '../../../../../../environments';
import { sortBy } from 'lodash-es';
import _ from 'lodash';

const errorMsg = 'es un campo obligatorio';
const msgError = {
  title: 'Error interno del servidor',
  message: 'Se ha generado un error interno, por favor contactar al admin',
};

export const EditArea = ({ onClose, area, isEdit = true }) => {
  const { reload } = useContext(AreasContext);
  const [selectedArea, setSelectedArea] = useState({
    ...area,
    leaders: area?.leaders?.map((item) => ({
      ...item,
      img: `${environments.imgUrl}${item.pslId}.jpg`,
    }))
  });
  const title = area.code ? 'Editar área de valoración' : 'Crear área de valoración';
  const [leads, setLeads] = useState([]);
  const [forms, setForms] = useState([]);
  const [interviewTypes, setInterviewTypes] = useState([...castInterviewType(area)]);
  const { register, handleSubmit, errors, setError, getValues, control, formState } = useForm({
    mode: 'onTouched',
    defaultValues: setDefaultValues(area),
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const formRef = useRef(null);
  const [numberInterview, setNumberInterview] = useState(area.interviewTypes.length);

  useEffect(() => {
    let mounted = true;
    getForms()
      .then((res) => sortBy(res, 'name', 'asc'))
      .then((res) =>
        res.map((form) => ({
          label: form.name,
          value: form.formId,
        })),
      )
      .then((res) =>
        setForms([{ label: '', value: '' }, { label: 'Perfilación', value: 'profiling-data-form' }, ...res]),
      )
      .catch(() => notificationService.showWarning(msgError.title, msgError.message));
    employeeService
      .getEvaluators()
      .then((res) => validateLeaders(res, selectedArea))
      .then(mounted && setLeads)
      .catch(() => notificationService.showWarning(msgError.title, msgError.message));
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Click submit
  const handleEditSave = () => {
    if (isValid || _.isEmpty(errors)) {
      formRef.current.dispatchEvent(new Event('submit'));
    } else {
      !getValues('name') && setError('name', { type: 'required', message: `Nombre ${errorMsg}` });
      !getValues('leaders').length &&
        setError('leaders', { type: 'required', message: `Líderes de práctica ${errorMsg}` });
    }
  };

  const callService = (code) => (code ? hamService.updateArea(code) : hamService.createArea);

  const onSubmit = (data) => {
    const newArea = {
      ...data,
      interviewTypes,
      leaders: data.leaders.map((lead) => ({ name: lead.name, pslId: lead.ldapId || lead.pslId })),
    };
    callService(selectedArea.code)(newArea)
      .then((request) => validateError(selectedArea.code, request, reload, onClose))
      .catch(() => notificationService.showWarning(msgError.title, msgError.message));
  };

  const modalButtons = [
    { label: 'Cancelar', variant: 'btn btn-secondary', onClick: () => onClose(false) },
    { label: 'Guardar', type: 'submit', onClick: () => handleEditSave() },
  ];

  const addInterview = () => {
    setNumberInterview(numberInterview + 1);
    setInterviewTypes([...interviewTypes, { key: numberInterview, name: '', subCategories: [] }]);
  };

  const removeInterview = (interviewType) =>
    setInterviewTypes(interviewTypes.filter(({ key }) => key !== interviewType.key));

  const changeInterview = (e, onChange) => {
    const updateInterview = interviewTypes.map((interview) => (interview.key === e.key ? e : interview));
    setInterviewTypes(updateInterview);
    onChange(updateInterview);
  };

  const filteredLeads = leads?.length && validateLeaders(leads, selectedArea);

  return (
    filteredLeads.length > 0 && (
      <ModalUI
        title={title}
        onClose={onClose}
        position="right"
        className="right-fold-modal d-flex flex-column position-fixed vh-100 bg-white"
        buttons={modalButtons}
      >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="form">
          <Input name="name" label="Nombre" register={register} error={errors.name} required />
          <div className="EditArea-form__title">
            <div>
              <span>Tipos de entrevistas</span>
            </div>
            <Button className="btn-secondary" onClick={addInterview}>
              Adicionar
            </Button>
          </div>
          <div className="EditArea-form__addInterview mb-4">
            {interviewTypes.map((interview) => (
              <Controller
                key={interview.key}
                name="interviewTypes"
                control={control}
                render={({ onChange }) => (
                  <InterviewType
                    isEdit={isEdit}
                    forms={forms}
                    changeInterview={(e) => changeInterview(e, onChange)}
                    interview={interview}
                    removeInterview={removeInterview}
                  />
                )}
                error={errors.interviewTypes}
              />
            ))}
          </div>
          {filteredLeads.length > 0 && (
            <Form.Group className="EditArea-form__leads required" controlId="leaders">
              <Form.Label>Líderes de práctica</Form.Label>
              <Controller
                name="leaders"
                control={control}
                render={({ onChange }) => (
                  <DropdownUI
                    isImg={true}
                    list={filteredLeads}
                    data={selectedArea.leaders}
                    valueFilter="name"
                    selections={({ detail }) => {
                      setSelectedArea((prev) => ({
                        ...prev,
                        leaders: detail
                      }));
                      onChange(detail)
                    }}
                  />
                )}
                error={errors.leaders}
              />
              {errors.leaders && <span className="text-danger small">{errors.leaders.message}</span>}
            </Form.Group>
          )}
        </form>
      </ModalUI>
    )
  );
};

EditArea.propTypes = {
  area: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    leaders: PropTypes.array,
    interviewTypes: PropTypes.array,
  }),
  onClose: PropTypes.func,
  isEdit: PropTypes.bool
};
