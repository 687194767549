import AuthenticationConnectorInstance from '../adapters/connectors/authentication.connector';
import { loaderService } from './loader.service';

// @ts-ignore
function request(url, { method = 'GET', body, showLoading = true } = {}) {
  if (showLoading) {
    loaderService.show();
  }

  return fetch(url, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${AuthenticationConnectorInstance.getToken()}`,
    },
    method,
  })
    .then((response) => handleResponse(response))
    .catch(handleError)
    .finally(() => loaderService.hide());
}

function handleResponse(response) {
  let isError = false;
  switch (response.status) {
    case 401:
      isError = true;
      AuthenticationConnectorInstance.clear();
      break;
    case 403:
      isError = true;
      break;
    case 404:
      throw response;
    case 500:
    case 504:
      throw response;
    default:
      return response.json().catch(() => response.body);
  }

  if (isError) {
    window.location.replace(`/error/${response.status}`);
    throw new Error();
  }
}

function handleError(error) {
  throw error;
}

export { request };
