import moment from 'moment';
import { notificationService, hamService } from '../../../../../../../services';
import { schedulingService } from '../../../services';
import { getFormData } from '../../../../../../../utils';
import environments from '../../../../../../../environments';
moment.locale('es-co');

export const filterNumbersFromText = (input) => {
  return [...input].filter(c => !(c.charCodeAt(0) >= 48 && c.charCodeAt(0) <= 57)).join('');
};


/**
 * Validate type evaluation and return array boolean according evaluation selected
 * @param {*} searchCriteriaType: string with type evaluation
 * @returns array the booleans
 */
export const getSearchParameters = (searchCriteriaType) => {
  switch (searchCriteriaType) {
    case 'inteler-technical':
      return [true, false, false];
    case 'inteler-profiler':
      return [false, true, false];
    case 'psyco':
      return [false, false, true];
    default:
      return [false, false, false];
  }
};

export const dateToTime = (date) => moment(date).format('HH:mm');

export const formatDate = (dateString) => moment(dateString).format('M/D/YYYY');
export const formatHour = (dateString) => moment(dateString).format('HH:mm');

const msgError = 'Ha ocurrido un error';
const msgAppointment = {
  rangeIncorrect: ['Rango de fecha incorrecto', 'El rango de hora debe ser menor a 2h'],
  evaluationExist: [msgError, 'El entrevistador ya tiene una entrevista con esta fecha de inicio'],
  sussesFullCreate: ['Entrevista agendada', `La entrevista fue creada satisfactoriamente`],
  notFindEmployee: [msgError, 'No hay candidatos registrados con ese correo'],
  tryLater: [msgError, 'Intentelo de nuevo o comuniquese con soporte'],
  appointmentEliminate: ['Opps', 'Esta citación ya ha sido eliminada o reagendada'],
  notUpdateAppointment: ['Oops', 'No se puede realizar la actualización'],
  sussesFullUpdate: ['Entrevista agendada', 'La reunión ha sido reprogramada con éxito'],
  errorAppoitment: ['Opps!', 'La entrevista no pudo ser agendada.']
};

/**
 * @description: join date with hour for create or update the appointment
 * @param {*} date: date appointment
 * @param {*} time: hour appointment
 * @returns date cast
 */
const timeToDate = (date, time) => {
  const [hour, minute] = time.split(':');
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour), Number(minute));
};
/**
 * function make object with basic structure to call service create or update appointment
 * @param {*} formData: information form Scheduling
 * @param {*} interviewer: information cast Scheduling
 * @returns simple body with structure create or update appointment
 */

const makeInternalAppointmentObject =
  (formData, interviewer) => (searchCriteria) => (employee, attendees, startDate, endDate) => ({
    ...formData,
    startDate,
    endDate,
    createOnlineMeeting: formData.createOnlineMeeting && formData.createOnlineMeeting === 'on' ? true : false,
    english: searchCriteria.english || false,
    attendees: [interviewer.email, ...attendees],
    interviewerPslId: interviewer.pslId,
    title: `${environments.env !== 'prod' ? 'PRUEBA ' : ''} ${employee.name} (${
      searchCriteria.interviewType.name
    })`,
    interviewer: {
      code: interviewer.email,
      name: interviewer.name,
    },
    area: {
      code: searchCriteria.area.code,
      interviewType: {
        code: searchCriteria.interviewType.code,
        subCategory: {
          code: searchCriteria.subCategory.code,
        },
      },
    },
    employee: {
      jauthId: employee.jauthId,
      name: employee.name,
      code: employee.email,
    },
  });

const CreateInternalAppointment = (employee) =>  employee ?
  schedulingService.createInterviewEvaluator :
  schedulingService.createInterview;

const errorNotFounded = 'employee-not-found';

export const onCreateInterAppointment =
  ({ event, employee, searchCriteria }, {history = {}, employeeAppointment}) =>
  async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = { description: '' };
    formData.forEach((value, key) => {
      data[key] = value;
    });

    const attendees = data.attendees.replace(/ /g, '').split(',');

    const startDate = timeToDate(event.start, data.start);
    const endDate = timeToDate(event.end, data.end);
    const diffDate = Math.abs(moment(startDate).diff(endDate, 'd'));
    if (diffDate > 2) {
      return notificationService.showWarning(
        msgAppointment.rangeIncorrect[0],
        msgAppointment.rangeIncorrect[1],
      );
    }
    try {
      const response = await CreateInternalAppointment(employeeAppointment)(
        makeInternalAppointmentObject(data, event.interviewer)(searchCriteria)(
          employee,
          attendees,
          startDate,
          endDate,
        ),
      );
      if (response.error === 'duplicate-startDate-for-interviewer') {
        notificationService.showWarning(msgAppointment.evaluationExist[0], msgAppointment.evaluationExist[1]);
        return false;
      }
      if(response.error) {
        notificationService.showError(msgAppointment.errorAppoitment[0], msgAppointment.errorAppoitment[1]);
        return true;
      }
      if (employeeAppointment) {
        // @ts-ignore
        history.push('../../my-interviews')
      }
      notificationService.showSuccess(msgAppointment.sussesFullCreate[0], msgAppointment.sussesFullCreate[1]);
      return true;
    } catch (error) {
      const err = await error;
      if (err.error === errorNotFounded) {
        notificationService.showWarning(
          msgAppointment.notFindEmployee[0],
          msgAppointment.notFindEmployee[1],
        );
        return false;
      } else {
        notificationService.showWarning(msgAppointment.tryLater[0], msgAppointment.tryLater[1]);
        return true;
      }
    }
  };

export const onUpdateAppointment = (interview, employee, searchCriteria, data) => async (e) => {
  e.preventDefault();
  const formData = getFormData(e.target);
  const attendees = formData.attendees.replace(/ /g, '').split(',');
  const startDate = timeToDate(data.start, formData.startDate);
  const endDate = timeToDate(data.end, formData.endDate);
  try {
    const result = await hamService.updateInterview(interview.appointmentId, {
      ...makeInternalAppointmentObject(formData, data.interviewer)(searchCriteria)(
        employee,
        attendees,
        startDate,
        endDate,
      ),
      oldInterviewerPslId: interview.interviewerPslId,
      addCv: formData.sendCv === 'on',
    });
    if (result.error) {
      if (result.error === 'appointment-already-deleted') {
        notificationService.showWarning(
          msgAppointment.appointmentEliminate[0],
          msgAppointment.appointmentEliminate[1],
        );
        return false;
      } else {
        notificationService.showWarning(
          msgAppointment.notUpdateAppointment[0],
          msgAppointment.notUpdateAppointment[1],
        );
        return false;
      }
    } else {
      notificationService.showSuccess(msgAppointment.sussesFullUpdate[0], msgAppointment.sussesFullUpdate[1]);
      return true;
    }
  } catch (err) {
    if (err.error === 'employee-not-found') {
      notificationService.showWarning(msgAppointment.notFindEmployee[0], msgAppointment.notFindEmployee[1]);
      return false;
    } else {
      notificationService.showWarning(msgAppointment.tryLater[0], msgAppointment.tryLater[1]);
      return true;
    }
  }
};
