import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useQuery, queryURL } from '../../../../../utils';
import { Table, BottomBar } from '../';

import { Form } from 'react-bootstrap';
import './sortable.scss'

const setOrder = (sort, sortBy, order) => {
  let orderBy = 'asc';
  if (sort === sortBy) {
    orderBy = order === 'asc' ? 'desc' : 'asc';
  }
  return orderBy;
};

export const Sortable = ({ thead = [], data = [], totalItems = 0, onSelect, selected, onChange, classTableName = '' }) => {
  const history = useHistory();
  const { sortBy, order, ...rest } = useQuery();

  const sortData = (sort) => {
    const orderBy = setOrder(sort, sortBy, order);
    const query = queryURL({ ...rest, page: 1, sortBy: sort, order: orderBy });
    history.push(query);
  };

  const setButtonType = ({ name, sortKey, filterKey, options }) => {
    if (sortKey) {
      return (
        <button className="btn btn-sort p-0 text-nowrap text-left align-text-left" onClick={() => sortData(sortKey)}>
          {name}
          <i className="icon-arrow-down"></i>
        </button>
      );
    }
    if (filterKey) {
      return (
        <Form.Control as="select" onChange={onChange} id={filterKey}>
          <option value="">{name || 'Seleccionar'}</option>
          {options.map((el, index) => (
            <option key={index} value={el.value}>
              {el.name}
            </option>
          ))}
        </Form.Control>
      );
    }
    return name;
  };

  const buttons = thead.map(({ ...rest }) => ({
    ...rest,
    name: setButtonType(rest),
  }));

  return (
    <>
      <div className="scrollable-x">
        <Table
          thead={buttons}
          data={data}
          onSelect={onSelect}
          selected={selected}
          classTableName={classTableName}
        />
      </div>
      <div className="botom-bar">
        {data.length ? <BottomBar totalItems={totalItems} /> : null}
      </div>
    </>
  );
};

Sortable.propTypes = {
  thead: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.any.isRequired,
      sortKey: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  classTableName: PropTypes.string,
};
