import { HamPermissions } from "../utils";

export const routesPermissionsMap = [
  { link: '/scheduling', text: 'Generar Citación', requirement: HamPermissions.administrator },
  { link: '/employees', text: 'Empleados', requirement: HamPermissions.interviewer },
  { link: '/processes', text: 'Procesos de selección', requirement: HamPermissions.administrator },
  { link: '/areas', text: 'Áreas de valoración', requirement: HamPermissions.administrator },
  { link: '/interviewers', text: 'Entrevistadores', requirement: HamPermissions.administrator },
  { link: '/interviews', text: 'Entrevistas', requirement: HamPermissions.administrator },
  { link: '/my-interviews', text: 'Mis Entrevistas', requirement: HamPermissions.interviewer },
  { link: '/schedule-analysis', text: 'Análisis Agenda', requirement: HamPermissions.administrator },
  { link: '/my-interviewer-configuration', text: 'Mi Perfil (Entrevistador)', requirement: HamPermissions.interviewer },
  { link: '/requests-status', text: 'Estado Solicitudes' },
];

export const validateLinkPermission = (permissions, url) => {
  const route = routesPermissionsMap.filter((item) => item.link === url)[0];
  if (route && (!route.requirement || permissions.includes(route.requirement))) {
    return route;
  }
  return null;
};
