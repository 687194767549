export const megaMenu = [
  {
    button: 'Configuración',
    dropdown: [
      {
        title: 'Entrevistas',
        links: ['/areas'],
      },
    ],
  },
  {
    button: 'Talento Humano',
    dropdown: [
      {
        title: 'Gestión Entrevistas',
        links: ['/my-interviewer-configuration', '/interviewers', '/interviews', '/my-interviews'],
      },
    ],
  },
  {
    button: 'Agenda',
    dropdown: [
      {
        title: 'Disponibilidad',
        links: ['/scheduling'],
      },
    ],
  },
  {
    button: 'Reporte',
    dropdown: [
      {
        title: 'Agenda',
        links: ['/schedule-analysis'],
      },
    ],
  },
];
