import React from 'react';
import styled, { keyframes } from 'styled-components';

const PURPLE = '#CC1F20';
const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;
export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 15px 20px;
  width: 160px;
  font-weight: bold;
  color: ${PURPLE};
  border: 1px solid ${PURPLE};
  border-radius: 8px;
`;
export const Spinner = styled.div`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-left-color: ${PURPLE};
  border-radius: 50%;
  animation: ${rotate} 1s ease-in-out infinite;
`;

export const Loader = () => (
  <LoaderWrapper>
    <Spinner /> Cargando
  </LoaderWrapper>
);
