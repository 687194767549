/* eslint-disable no-undef */
// Environment variables
import packageJson from '../package.json';

const version = packageJson.version;
const imgPsl = 'https://psl-labs.s3.us-east-2.amazonaws.com/employees/';

const localEnv = {
  hamUrl: 'http://localhost:3303',
  jAuthApplicationCode: 'tam-local',
  jAuthUrl: 'http://localhost:3301',
  version,
  env: 'local',
  evaluations: 'http://localhost:3304/',
  skills: 'http://localhost:3305/',
  users_rest: 'http://localhost:3300/',
  admin: 'http://localhost:3302/',
  sercurity_mode: process.env.REACT_APP_LH_SECURITY_MODE,
  imgUrl: imgPsl,
};

const devEnv = {
  hamUrl: 'https://tam.psl.xyz:7443',
  jAuthApplicationCode: 'tam-dev',
  jAuthUrl: 'https://jauth.psl.xyz:7443',
  version,
  env: 'dev',
  evaluations: 'https://lighthouse.psl.xyz:7443/api/evaluations/v2/',
  skills: 'https://lighthouse.psl.xyz:7443/api/skills/v2/',
  users_rest: 'https://lighthouse.psl.xyz:7443/api/users/v1/',
  admin: 'https://admin.lighthouse.psl.xyz:7443/api/admin/v1/',
  sercurity_mode: process.env.REACT_APP_LH_SECURITY_MODE,
  imgUrl: imgPsl,
};

const prodEnv = {
  hamUrl: 'https://tam.psl.xyz',
  jAuthApplicationCode: 'tam',
  jAuthUrl: 'https://jauth.psl.xyz',
  version,
  env: 'prod',
  evaluations: 'https://lighthouse.psl.xyz/api/evaluations/v2/',
  skills: 'https://lighthouse.psl.xyz/api/skills/v2/',
  users_rest: 'https://lighthouse.psl.xyz/api/users/v1/',
  admin: 'https://admin.lighthouse.psl.xyz/api/admin/v1/',
  sercurity_mode: process.env.REACT_APP_LH_SECURITY_MODE,
  imgUrl: imgPsl,
};

const qaEnv = {
  hamUrl: 'https://tam.psl.xyz:9443',
  jAuthApplicationCode: 'tam-qa',
  jAuthUrl: 'https://jauth.psl.xyz:9443',
  version,
  env: 'qa',
  evaluations: 'https://lighthouse.psl.xyz:9443/api/evaluations/v2/',
  skills: 'https://lighthouse.psl.xyz:9443/api/skills/v2/',
  users_rest: 'https://lighthouse.psl.xyz:9443/api/users/v1/',
  admin: 'https://admin.lighthouse.psl.xyz:9443/api/admin/v1/',
  sercurity_mode: process.env.REACT_APP_LH_SECURITY_MODE,
  imgUrl: imgPsl,
};

export default (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return prodEnv;
    case 'dev':
      return devEnv;
    case 'qa':
      return qaEnv;
    default:
      return localEnv;
  }
})();
