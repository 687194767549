import './login.scss';
import React from 'react';
import logo from '../../assets/images/tam-logo.svg';
import AuthenticationConnectorInstance from '../../adapters/connectors/authentication.connector';

const Login = () => {
  const linkJAuth = AuthenticationConnectorInstance.getAuthenticationRedirectUrl();

  return (
    <div className="home-video vh-100">
      <video id="video" loop autoPlay muted>
        <source
          src="https://lighthouse.psl.xyz/assets/img/svg/content/video_background.mp4"
          type="video/mp4"
        />
      </video>
      <div className="h-100">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="content-login">
            <img src={logo} alt="Logo" />
            <a className="btn btn-primary mt-5" href={linkJAuth}>
              Log in with Office 365
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
