import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { UserContext } from '../../../../context';
import { validateLinkPermission } from '../../../../context/routesPermissionsMap';

import { Bookmark } from './Bookmark';
import { hamService } from '../../../../services';

// Load bookmarks from API
const loadBookmarks = async () => {
  let bookmarkList = [];
  try {
    const { bookmarks } = await hamService.getBookmarks();
    bookmarkList = bookmarks;
  } catch (err) {
    hamService.createBookmarks({ bookmarks: [] });
  }
  return bookmarkList;
};

// If bookmark don't exist update in the API
const validBookmark = (bookmarks, bookmark, set, user) => {
  if (
    bookmark !== '' &&
    bookmark !== '/' &&
    !bookmarks.includes(bookmark) &&
    validateLinkPermission(user.roles, bookmark)
  ) {
    const newArr = [...bookmarks, bookmark];
    hamService.updateBookmarks({ bookmarks: newArr });
    set(newArr);
  }
};

const addFirstBookmark = (bookmarks, firstLoad, add, set) => {
  if (bookmarks && !firstLoad) {
    add();
    set(true);
  }
};

const addNewBookmark = (bookmarks, add) => {
  if (bookmarks) add();
};

export const Bookmarks = () => {
  const [bookmarks, setBookmarks] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);

  const { user } = useContext(UserContext);

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  // Load bookmarks
  useEffect(() => {
    let mounted = true;
    (async () => {
      const bookmarks = await loadBookmarks();
      if (mounted) setBookmarks(bookmarks);
    })();
    return () => (mounted = false);
  }, []);

  // Add valid bookmark when URL change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => addNewBookmark(bookmarks, addBookmark), [history.location]);

  // Add default page on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => addFirstBookmark(bookmarks, firstLoad, addBookmark, setFirstLoad), [bookmarks]);

  const getBookmarkRoute = () => `/${location.pathname.split('/')[2]}`;

  const addBookmark = () => {
    const bookmark = getBookmarkRoute();
    validBookmark(bookmarks, bookmark, setBookmarks, user);
  };

  // Delete bookmark and update route
  const deleteBookmark = (i) => {
    const bookmark = bookmarks[i];
    const url = getBookmarkRoute();   
    const newArr = [...bookmarks];
    newArr.splice(i, 1);
    hamService.updateBookmarks({ bookmarks: newArr });
    setBookmarks(newArr);

    if (bookmark === url && newArr.length > 0) {
      history.push(`/admin${newArr[i > 0 ? i - 1 : 0]}`);
    }
  };

  const createBookmark = (link, i) => {
    const route = validateLinkPermission(user.roles, link);
    if (route) {
      const url = `${match.path}${route.link}`;
      const isActive = location.pathname.includes(url);
      return (
        <Bookmark
          key={`bookmark_${i}`}
          label={route.text}
          to={url}
          i={i}
          canDelete={bookmarks.length > 1}
          onDelete={deleteBookmark}
          isActive={isActive}
        />
      );
    }
  };

  if (!bookmarks || !bookmarks.length) return null;

  return (
    <ul className="list-unstyled bg-light pt-2 px-2 mb-0 bookmarks">
      {bookmarks.map((link, i) => createBookmark(link, i))}
    </ul>
  );
};
