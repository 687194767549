let listener = null;
const subscribe = (cb) => {
  listener = cb;
};

const showError = (title, message) => {
  listener({ message, title, type: 'danger' });
};

const showWarning = (title, message) => {
  listener({ message, title, type: 'warning' });
};

const showSuccess = (title, message) => {
  listener({ message, title, type: 'success' });
};

const showInfo = (title, message) => {
  listener({ message, title, type: 'info' });
};

const notificationService = {
  showSuccess,
  showWarning,
  showError,
  showInfo,
  subscribe,
};
export { notificationService };
