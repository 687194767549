import React from 'react';
import PropTypes from 'prop-types';


export const TableRow = ({row, selected, children}) => (
  <tr className={[row.className || '', selected && row.id === selected ? 'bg-light' : null].join(' ')}>
    {children}
  </tr>
);

TableRow.propTypes = {
  row: PropTypes.shape({
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    id: PropTypes.string,
  }),
  selected: PropTypes.string,
  children: PropTypes.any,
};