import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../../../context';
import { validateLinkPermission } from '../../../../../context/routesPermissionsMap';

export const MegaDropdown = ({ data = [] }) => {
  const { user } = useContext(UserContext);
  const match = useRouteMatch();

  const createLink = (link, i) => {
    const route = validateLinkPermission(user.roles, link);
    if (route) {
      return (
        <Link
          key={`link_${i}`}
          to={`${match.path}${route.link}`}
          className="d-block p-3 text-decoration-none text-nowrap"
        >
          {route.text}
        </Link>
      );
    }
  };

  return (
    <div className="bg-light p-4 shadow rounded megadropdown">
      {data.map(({ title, links }, i) => (
        <div key={i} className="col">
          <h6 className="text-nowrap px-3 mb-1">{title}</h6>
          {links ? links.map((link, i) => createLink(link, i)) : null}
        </div>
      ))}
    </div>
  );
};

MegaDropdown.propTypes = {
  data: PropTypes.array,
};
