import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../Shared';
import { employeeService, hamService, notificationService, storageService } from '../../../../../services';

export const SaveEvaluation = ({ interviewId, evaluation, closeModal, history, setClear }) => {
  
  const handleSubmit = async () => {
    try {
      const res = await employeeService.postSkillsInterview(evaluation);
      if (!res.error && !res.statusCode) {
        const res = await hamService.setInterviewState(interviewId);
        if(!res.error && !res.statusCode){
          setClear(true);
          notificationService.showSuccess('Guardado exitoso', 'Datos enviados correctamente');
          history.replace('/admin/my-interviews');
          storageService.clearInterview();
          closeModal(true, null);
        } else {
          notificationService.showWarning('Ooops!',res.error || res.message);
        }
      } else {
        notificationService.showWarning('Ooops!',res.error || res.message);
      }      
    } catch (error) {
      notificationService.showWarning('Ooops!','Ocurrió algo inesperado');
    }
  };

  const modalButtons = [
    { label: 'No', onclick: () => closeModal(false, evaluation.employeeSkill), auxClass: 'btn-secondary' },
    { label: 'Si', onclick: handleSubmit, auxClass: 'btn-primary' },
  ];

  return (
    <>
      <Modal
        show={!!evaluation}
        title="Guardar valoración"
        buttons={modalButtons}
        onClose={() => closeModal(false)}
      >
        <div className="d-flex align-items-center flex-column evaluation-template">
          <p>Al enviar esta valoración no podrás hacerle cambios. En tal caso</p>
          <p>
            debes comunicarte con <strong>Staffing</strong> para hacer algún ajuste
          </p>
          <p className="font-weight-bold">¿Deseas enviar la valoración?</p>
        </div>
      </Modal>
    </>
  );
};

SaveEvaluation.propTypes = {
  interviewId: PropTypes.string.isRequired,
  evaluation: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setClear: PropTypes.func.isRequired,
};
