import React from 'react';
import PropTypes from 'prop-types';

import { Button, DatePicker, InputList } from '../';
import { NAME_MIN_LENGTH, TODAY, NAV_WIDTH } from './constants';

export const EvaluationHeader = ({
  evaluator,
  evaluators = [],
  onChangeEvaluator,
  date,
  onChangeDate,
  onClickSave,
  isTemplate = false
}) => {
  return (
    <div className="d-flex align-items-end mb-3">
      {!isTemplate ? (
      <>
        <div className="form-group mb-0">
          <label className="form-label pl-2">Valorado por:</label>
          <InputList
            options={evaluators}
            className={`bg-white ${evaluator.trim().length < NAME_MIN_LENGTH ? 'border-danger' : null}`}
            showList={evaluator.length > 2}
            defaultValue={evaluator}
            onKeyUp={onChangeEvaluator}
            style={{ width: NAV_WIDTH }}
          />
        </div>
        <div className="form-group ml-3 mb-0">
          <label className="form-label pl-2">Fecha valoración:</label>
          <DatePicker className="bg-white" value={date} onChange={onChangeDate} max={TODAY} />
        </div>
      </>
      ) : <></>}
      <div className="ml-auto">
        <Button onClick={onClickSave}>Enviar</Button>
      </div>
    </div>
  );
};

EvaluationHeader.propTypes = {
  evaluator: PropTypes.string,
  evaluators: PropTypes.array,
  onChangeEvaluator: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  isTemplate : PropTypes.bool,
};
