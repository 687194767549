import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '../../Shared';

export const RescheduleIcon = ({ setEditInterview, interview }) => {

  return (
    <>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-reschedule'}>
            Reagendar
          </Tooltip>
        }
      >
        <Icon
          disabled={!!interview.state}
          style={{height: '1em'}}
          icon={'pen'}
          onClick={() => {window.scrollTo(0,0); setEditInterview(interview)}} 
        />
      </OverlayTrigger>
    </>
  );
};
RescheduleIcon.propTypes = {
  setEditInterview: PropTypes.func,
  interview: PropTypes.any,
};