import React from 'react';

export const Search = ({ ...props }) => {
  return (
    <div className="d-flex align-items-center mr-3">
      <i className="material-icons position-absolute mx-3 text-primary cursor-default">search</i>
      <input type="text" placeholder="Buscar" className="form-control h-100 pl-5" {...props} />
    </div>
  );
};
