import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { hamService } from '../services';
import * as _ from 'lodash';

export const useQuery = () => {
  const query = new URLSearchParams(useLocation().search);
  return {
    page: parseInt(query.get('page')) || 1,
    pageSize: parseInt(query.get('pageSize')) || 10,
    q: query.get('q') || '',
    sortBy: query.get('sortBy') || '',
    order: query.get('order') || '',
    type: query.get('type') || '',
    filterByType: query.get('filterByType') || '',
    filterByArea: query.get('filterByArea') || '',
    filterByLevel: query.get('filterByLevel') || '',
  };
};

export const queryURL = (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => [key, params[key]].join('='))
    .join('&');
  return `?${query}`;
};

export const serializeFormData = (form) => {
  const employee = { additionalInformation: {} };
  for (let i = 0; i < form.elements.length; i++) {
    let element = form.elements[i];
    let key = element.name;
    let value = element.value;
    if (key !== '' && value !== '') {
      let type = element.type;
      if ((key === 'github' || key === 'linkedIn' || key === 'skype') && value.trim() !== '') {
        employee.additionalInformation[key] = value;
      } else {
        employee[key] = type === 'number' ? parseInt(value) : value;
      }
    }
  }
  return employee;
};

export const formatDate = (date = new Date(), separator = '/') => {
  return `${('0' + date.getDate()).slice(-2)}${separator}${('0' + date.getMonth()).slice(
    -2,
  )}${separator}${date.getFullYear()}`;
};

export const generateOptionsFromNumber = (number) => {
  let arr = [];
  for (let index = 1; index < number + 1; index++) {
    arr = [...arr, { label: index.toString(), value: index }];
  }
  return arr;
};

export const generateOptionsFromNumberArray = (numbers) =>
  Array.from(numbers, (number) => ({ label: number.toString(), value: number }));

export const capitalize = (string) => `${string[0].toUpperCase()}${string.slice(1)}`;

export const formatAppointmentDate = (startDate) => (endDate) =>
  `${moment(startDate).format('DD/MM/YYYY')} \n ${moment(startDate).format('hh:mma')} ${moment(endDate).format(
    'hh:mma',
  )}`;

export const insertArray = (array) => (element) => (position) => {
  if (position >= array.length) {
    array.push(element);
  } else if (position <= 0) {
    array.unshift(element);
  } else {
    for (let i = array.length; i >= position; i--) {
      array[i] = array[i - 1];
    }
    array[position] = element;
  }
  return array;
};

export const getFormData = (form) => {
  const formData = new FormData(form);
  const employee = {};
  for (var [key, value] of formData.entries()) {
    employee.additionalInformation = {};
    if (key === 'github' || key === 'linkedIn' || key === 'skype') {
      if (key === 'linkedIn') {
        employee.additionalInformation[key] = `linkedin.com/in/${value}`;
      } else if (value.trim() !== '') {
        employee.additionalInformation[key] = value;
      }
    } else if (key === 'canSpeakEnglish') {
      employee[key] = value === 'true' ? true : false;
    } else {
      employee[key] = value;
    }
  }
  return employee;
};

// Get rolelist
export const getRoleList = async () => {
  try {
    return sortCollection(await hamService.getRoleList())()()();
  } catch (error) {
    return [];
  }
};

// Get rolelist
export const getSourceSV = async () => {
  try {
    return sortCollection(await hamService.getSourceSV())('name')('name')();
  } catch (error) {
    return [];
  }
};

// Get country list
export const loadCountries = async () => {
  try {
    return sortCollection(await hamService.getCountries())('countryName')('countryCode')();
  } catch (err) {
    return [];
  }
};

// Get state list
export const loadStates = async (countryCode) => {
  return sortCollection(await hamService.getStates(countryCode))('stateName')('stateName')();
};

// Get city list
export const loadCities = (countryCode) => async (stateName) => {
  return sortCollection(await hamService.getCities(countryCode)(stateName))()('name')();
};

// Input only numbers
export const validNumber = (set) => (e) => {
  const regexp = /^[0-9]*$/;
  const numberInput = e.target.value;
  if (numberInput === '' || regexp.test(numberInput)) {
    set(numberInput);
  }
};
/**
 * Taken from https://www.freecodecamp.org/news/https-medium-com-gladchinda-hacks-for-creating-javascript-arrays-a1b80cb372b/
 * @param {Number} start
 * @param {Number} end
 * @param {Number} step
 */
export const range = (start, end, step = 1) => {
  const allNumbers = [start, end, step].every(Number.isFinite);
  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.');
  }
  if (step <= 0) {
    throw new Error('step must be a number greater than 0.');
  }
  if (start > end) {
    step = -step;
  }
  const length = Math.floor(Math.abs((end - start) / step)) + 1;
  return Array.from(Array(length), (x, index) => ({
    label: `${start + index * step}`,
    value: start + index * step,
  }));
};

export const sortCollection = (collection = []) => (name = 'name') => (value = 'code') => (
  order = 'desc',
) => {
  return _.sortBy(
    collection.map((element) => ({
      label: element[name],
      value: element[value],
      sortingKey: _.lowerCase(element[name]),
    })),
    'sortingKey',
    order,
  );
};

export const removeEmptyFields = (data) => {
  Object.keys(data).forEach((key) => {
    if (key === 'additionalInformation') {
      Object.keys(data.additionalInformation).forEach((subkey) => {
        if (data.additionalInformation[subkey] === '' || data.additionalInformation[subkey] === null) {
          delete data.additionalInformation[subkey];
        }
      });
    }
    if (data[key] === '' || data[key] === null) {
      delete data[key];
    }
  });
};

export const parseBoolean = (value) => {
  return value === 'true';
};

export const useParamsUrl = (str) => {
  const query = new URLSearchParams(useLocation().search);
  return query.get(str) || '';
};

export const strFormatter = (str) => str.replace(/[^a-zA-Z ]/g, '');
