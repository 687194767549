import environments from '../../environments';
import { LegacyAuthenticationAdapter } from '../authentication/legacy-authentication.adapter';
import { UpgradedAuthenticationAdapter } from '../authentication/upgraded-authentication.adapter';

export const AuthenticationFactory = (function () {
  let instance;
  return {
    getInstance: function () {
      if (instance === undefined) {
        if (environments.sercurity_mode === 'upgraded' && UpgradedAuthenticationAdapter) {
          instance = new UpgradedAuthenticationAdapter();
        } else if (LegacyAuthenticationAdapter) {
          instance = new LegacyAuthenticationAdapter();
        } else {
          instance = {};
        }
        instance.constructor = null;
      }
      return instance;
    },
    clearInstance: function () {
      instance = undefined;
    },
  };
})();
