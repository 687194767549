import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';

import { AreasContext } from '../../../../../context';
import { hamService, notificationService } from '../../../../../services';
import { areasToTree, findCheckedAreas, checkedToObj } from './utils';
// components
import { Button } from '../../Shared';

export const CheckAreas = ({ data: { _id, areas, isAdmin  }, meMode }) => {
  const [tree, setTree] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const { areas: hamAreas } = useContext(AreasContext);

  useEffect(() => {
    setTree(areasToTree(hamAreas || []));
    if (areas) {
      setChecked(findCheckedAreas(areas));
    }
  }, [hamAreas, areas]);

  const updateAreas = () => {
    const areas = checkedToObj(checked);
    hamService
      .pathInterviewer(meMode ? 'me' : _id, { areas: areas })
      .then(() => notificationService.showSuccess('Actualización exitosa', 'Áreas guardadas con éxito'))
      .catch(() => notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado'));
  };

  return (
    <div>
      <CheckboxTree
        nodes={tree}
        checked={checked}
        expanded={expanded}
        onCheck={(checked) => setChecked(checked)}
        onExpand={(expanded) => setExpanded(expanded)}
        disabled={!isAdmin}
        showExpandAll
        icons={{
          check: <i className="material-icons">check_box</i>,
          uncheck: <i className="material-icons">check_box_outline_blank</i>,
          halfCheck: <i className="material-icons">indeterminate_check_box</i>,
          expandClose: <i className="material-icons">keyboard_arrow_right</i>,
          expandOpen: <i className="material-icons">keyboard_arrow_down</i>,
          expandAll: <i className="material-icons">expand_more</i>,
          collapseAll: <i className="material-icons">expand_less</i>,
          parentClose: <i className="material-icons">folder</i>,
          parentOpen: <i className="material-icons">folder_open</i>,
          leaf: <i className="material-icons">insert_drive_file</i>,
        }}
      />
      {isAdmin ? (
        <Button onClick={updateAreas} className="mt-5">
          Guardar
        </Button>
      ) : (
        <> </>
      )}
    </div>
  );
};

CheckAreas.propTypes = {
  data: PropTypes.any.isRequired,
  meMode: PropTypes.bool,
};
