import React, { useContext, useEffect, useState } from 'react';
// components
import { Button } from '../Shared';
import { EditArea, EditArea as CreateArea, TableAreas } from './components';
// context
import { AreasContext } from '../../../../context';
// styles
import './Areas.scss';

export const Areas = () => {
  const { reload } = useContext(AreasContext);
  const [selectedAreaToEdit, setSelectedAreaToEdit] = useState(undefined);
  const [selectedAreaToCreate, setSelectedAreaToCreate] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reload(), ['']);

  const onCloseModal = () => {
    setSelectedAreaToCreate(undefined);
    setSelectedAreaToEdit(undefined);
  };

  const handleCreate = () => {
    setSelectedAreaToCreate({ code: '', name: '', leaders: [], interviewTypes: [] });
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="d-flex justify-content-between mb-5 align-center">
          <h3>Áreas de valoración</h3>
          <Button onClick={() => handleCreate()}>Crear área</Button>
        </div>
        <div className="card card-table">
          <TableAreas onEditArea={setSelectedAreaToEdit} />
          {selectedAreaToEdit && <EditArea onClose={onCloseModal} area={selectedAreaToEdit} />}
          {selectedAreaToCreate && <CreateArea isEdit={false} onClose={onCloseModal} area={selectedAreaToCreate} />}
        </div>
      </div>
    </>
  );
};
