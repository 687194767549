import React, { useContext, useEffect, useState, useRef } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';

import { UserContext } from '../../../../context';

import { SkillEvaluationUI } from '../Shared';

import { SaveEvaluation, TODAY, getAPIData, searchForm, sendData, validateDate } from './components';

export const TemplateEvaluation = () => {
  const { employeeId, formId, interviewId } = useParams();
  const history = useHistory();

  const [date, setDate] = useState('');
  const [form, setForm] = useState([]);
  const [data, setData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [clear, setClear] = useState(false);
  const [evaluator, setEvaluator] = useState('');
  const [evaluators, setEvaluators] = useState([]);
  const [employee, setEmployee] = useState({name: '', email: ''});
  const [saveEvaluation, setSaveEvaluation] = useState(undefined);
  const [currentForm, setCurrentForm] = useState({ formId: '', name: '' });
  const {
    user: { name },
  } = useContext(UserContext);

  // Manage evaluator/date refs
  const dateRef = useRef(date);
  const evaluatorRef = useRef(evaluator);
  const employeeRef = useRef(employee);

  useEffect(() => {
    evaluatorRef.current = evaluator;
    dateRef.current = date;
    employeeRef.current = employee;
  }, [evaluator, date, employee]);

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const [evaluators, employee, form, forms, skills] = await getAPIData(employeeId, formId);
      if (isAlive) {
        setForm(form);
        setSkills(skills);
        setEmployee(employee);
        setEvaluators(evaluators);
        setEvaluator(name);
        setDate(TODAY);
        setCurrentForm(searchForm(forms, formId));
      }
    })();
    return () => {
      isAlive = false;
    }
  }, [employeeId, name, formId]);

  useEffect(()=>{
    return ()=>{
      setSaveEvaluation(undefined);
    }
  },[])

  // EvaluationHeader handlers
  const handleEvaluatorChange = (e) => setEvaluator(e.target.value);
  const handleDateChange = (e) => setDate(validateDate(e.target.value));

  // Submit data
  const handleSubmit = sendData(
    setSaveEvaluation,
  )({
    employeeEmail: employee.email    
  });

  const onCloseModal = (result, employeeSkill = []) => {
    if (!result) {
      setData(employeeSkill);
      setSaveEvaluation(undefined);
    } 
  };

  if (!employee) return null;
  return (
    <>
      <Prompt when={!saveEvaluation} message="No se ha valorado ninguna competencia" />
      <div className="p-5 container-fluid">
        <div style={{ marginBottom: '-40px' }}>
          <h1> Valoración de <strong> {employee.name} </strong></h1>
          <span>{currentForm.name}</span>
        </div>
        {currentForm.formId && skills.length > 0 && (
          <SkillEvaluationUI
            form={currentForm.formId}
            evaluated={employee.name}
            date={date}
            skillData={form}
            skills={skills}
            evaluator={evaluator}
            evaluators={evaluators}
            handleSubmit={handleSubmit}
            handleDateChange={handleDateChange}
            handleEvaluatorChange={handleEvaluatorChange}
            isTemplate={true}
            saveDataAgain={data}
            isClean={clear}
          />
        )}
        <SaveEvaluation
          interviewId={interviewId}
          evaluation={saveEvaluation}
          closeModal={onCloseModal}
          history={history}
          setClear={setClear}
        />
      </div>
    </>
  );
};
