import Cookies from 'js-cookie';
import _ from 'lodash';
import { routesPermissionsMap } from '../../context/routesPermissionsMap';
import environments from '../../environments';
import { request } from '../../services/api.service';
import { HamPermissions } from '../../utils';

const userCookie = 'user';
const tokenCookie = 'token';

const getAccess = (permissions) => {
  return permissions.reduce((accumulator, permission) => {
    const result = _.filter(routesPermissionsMap, ['requirement', permission]);
    if (result.length) {
      accumulator.push(...result);
    }
    return accumulator;
  }, []);
};

export class LegacyAuthenticationAdapter {
  logger() {
    console.log('this is legacy mode');
  }

  onLogin(newToken) {
    this.setToken(newToken);
  }

  onLogout() {
    this.clear();
  }

  manageTokenAndUser(userData) {
    userData.accessPages = getAccess(userData.permissions);
    userData.accessPages.push(...routesPermissionsMap.filter((r) => !r.requirement));
    return this.setUser(userData);
  }

  getUser() {
    try {
      const user = Cookies.get(userCookie);
      return user ? JSON.parse(user) : undefined;
    } catch (exception) {
      return undefined;
    }
  }

  setUser(user) {
    const newUser = {
      accessPages: user.accessPages,
      email: user.pslEmail,
      name: user.name,
      roles: user.permissions,
      id: user.jauthId,
      isAdmin: user.permissions.includes(HamPermissions.administrator),
    };
    Cookies.set(userCookie, JSON.stringify(newUser));
    return newUser;
  }

  setToken(token) {
    Cookies.set(tokenCookie, token);
  }

  getToken() {
    return Cookies.get(tokenCookie);
  }

  // clean user and token
  clear() {
    Cookies.remove(userCookie);
    Cookies.remove(tokenCookie);
  }

  getMe() {
    return request(`${environments.hamUrl}/api/v2/me`, {
      body: undefined,
      method: 'GET',
      showLoading: true,
    });
  }

  getAuthenticationRedirectUrl() {
    const { jAuthUrl: url, jAuthApplicationCode: code } = environments;
    return `${url}/api/v2/authentication/login?app-code=${code}`;
  }
}
