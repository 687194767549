import React from 'react';
import styled from 'styled-components';

const SwitchWrapper = styled.label`
  display: block;
  width: 30px;
  height: 20px;
  margin: auto;
`;

const SwitchInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:not(:disabled) + span {
    cursor: pointer;
  }
  &:checked + span {
    background: #CC1F20;
    &::before {
      transform: translateX(10px);
    }
  }
  &:disabled + span {
    background: #e7e7e7;
    opacity: 0.5;
  }
`;

const SwitchSpan = styled.span`
  box-sizing: border-box;
  display: block;
  padding: 2px;
  width: 30px;
  height: 20px;
  background: #e7e7e7;
  border-radius: 20px;
  transition: background-color 0.3s;
  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 8px;
    transition: transform 0.3s;
  }
`;

export const Switch = ({ ...props }) => (
  <SwitchWrapper>
    <SwitchInput type="radio" {...props} />
    <SwitchSpan />
  </SwitchWrapper>
);
