import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../Shared';
import { hamService, notificationService } from '../../../../../services';

export const CancelInterview = ({ interview, closeModal }) => {
  const onCancelInterview = () => {
    (async () => {
      const result = await hamService.deleteInterview(interview.appointmentId, interview.interviewerPslId);
      if (result && result.error) {
        notificationService.showWarning('Oops', 'Ocurrió un error inesperado');
      } else {
        notificationService.showSuccess('Borrado exitoso', 'Se ha eliminado la entrevista con éxito');
      }

      return closeModal(true);
    })();
  };

  const modalButtons = [
    { label: 'Cancelar', onclick: () => closeModal(false), auxClass: 'btn-secondary' },
    { label: 'Continuar', onclick: onCancelInterview, auxClass: 'btn-primary' },
  ];

  if (!interview) return null;
  return (
    <Modal
      show={!!interview}
      title="Cancelar Entrevista"
      buttons={modalButtons}
      onClose={() => closeModal(false)}
    >
      <p>¿Está Seguro de Cancelar la Entrevista?</p>
    </Modal>
  );
};

CancelInterview.propTypes = {
  interview: PropTypes.object,
  closeModal: PropTypes.func,
};
