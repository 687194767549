import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import iconSrc from './images/tooltip.svg';

export const Tooltip = ({ text = '' }) => (
  <Wrapper>
    <img src={iconSrc} alt="Tooltip" />
    <span>{text}</span>
  </Wrapper>
);

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: top;
  img {
    display: inline-block;
    height: 20px;
  }
  span {
    position: absolute;
    z-index: 1;
    display: none;
    max-width: 50%;
    margin-top: -10px;
    margin-right: 10px;
    margin-left: 30px;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: normal;
    line-height: 2;
    color: white;
    background: #444444;
    white-space: normal;
    border-radius: 4px;
  }
  &:hover span {
    display: block;
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: -18px;
      display: block;
      border: 10px solid transparent;
      border-right-color: #444444;
    }
  }
`;
