import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { SchedulingContext } from '../../context';
import { Table, ConfirmationModal } from '../../../Shared';
import { Form } from 'react-bootstrap';

const columns = [
  { key: 'email', name: 'Email' },
  { key: 'name', name: 'Nombre' },
  { key: 'english', name: 'Ingles' },
  { key: 'status', name: 'Estado' },
  { key: 'actions', name: '' },
];
const calculateStatus = (interviewer, onLoadOmitted) => {
  if (interviewer.appointments.error === 'office-non-credentials') {
    return 'Este usuario no ha iniciado sesión con Office';
  }
  if (interviewer.appointments.error === 'office-invalid-authentication-token') {
    return 'Este usuario debe iniciar sesión en Office nuevamente'
  }
  if (interviewer.appointments.error === 'user-not-found') {
    return 'Este usuario no existe en el sistema';
  }
  if (interviewer.appointments.error === 'unknown-error') {
    return 'Error no esperado';
  }
  if (!interviewer.appointments.hasIntelerEvents) {
    return 'Este usuario no ha configurado inteler';
  }
  const availability = interviewer.appointments.availability || [];
  const omitted = interviewer.appointments.omitted || [];
  return (
    <>
      <span>{availability.length} horarios disponibles.</span>
      {omitted.length ? (
        <button className="btn btn-link" onClick={() => onLoadOmitted(interviewer)}>
          Cargar {omitted.length} omitidos
        </button>
      ) : null}
    </>
  );
};

const Switch = (i, value, action) => (
  <Form.Check type="switch" id={`custom-switch-${i}`} label="" onChange={action} checked={value} />
);

export const ListInterviewers = ({ requiredDisponibility, updateRequiredDisponibility }) => {
  let { interviewers, loadOmitted } = useContext(SchedulingContext);
  const [selectedOmitted, setSelectedOmitted] = useState(null);
  if (!interviewers) {
    return null;
  }
  const data = interviewers.map((interviewer, i) => ({
    email: interviewer.email,
    english: interviewer.configuration.english ? 'Si' : 'No',
    name: interviewer.name,
    status: calculateStatus(interviewer, setSelectedOmitted),
    actions: Switch(i, requiredDisponibility.includes(interviewer._id), () =>
      updateRequiredDisponibility(interviewer._id),
    )
  }));
  const onLoadOmittedAvailability = () => {
    loadOmitted(selectedOmitted);
    setSelectedOmitted(undefined);
  };

  // No interviewers? No render
  if (!interviewers) {
    return null;
  }
  return (
    <>
      {interviewers.length ? (
        <Table thead={columns} data={data}></Table>
      ) : (
        <p className="my-4">No hay entrevistadores disponibles</p>
      )}
      {selectedOmitted ? (
        <ConfirmationModal
          title="Cargar disponibilidad oculta"
          question="El entrevistador se encuentra ya al maximo de su capacidad. ¿realmente desea cargar su disponibilidad?"
          onOptionSelected={(answer) =>
            answer ? onLoadOmittedAvailability() : setSelectedOmitted(undefined)
          }
        />
      ) : null}
    </>
  );
};

ListInterviewers.propTypes = {
  requiredDisponibility: PropTypes.any,
  updateRequiredDisponibility: PropTypes.func,
};
