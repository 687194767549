import { Redirect, useLocation } from 'react-router-dom';
import React from 'react';
import { useUserContext } from '../../context';

const LoginCallback = () => {
  const URLSearch = new URLSearchParams(useLocation().search);
  const callbackToken = URLSearch.get('token');
  const { onLogin, user } = useUserContext();

  if (callbackToken && !user) {
    onLogin(callbackToken);
  }

  return callbackToken ? (
    <>
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col text-center">
            <span>Te estamos redirigiendo...</span>
          </div>
        </div>
      </div>
      {user && callbackToken ? <Redirect to="/admin" /> : null}
    </>
  ) : (
    <Redirect to="/error/404" />
  );
};

export { LoginCallback };
