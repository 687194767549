import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Select, ModalLeft, Button } from '../../Shared';
import { notificationService, hamService } from '../../../../../services';
import { TablePositions } from './TablePositions';
import { RequiredPosition } from './RequiredPosition';
import { generateOptionsFromNumber } from '../../../../../utils';
import './Configuration.scss';

const TOTAL_DAYS = 5;

export const Configuration = ({ config, onGenerateReport, disabledUpdateButton }) => {
  const [showModal, setShowModal] = useState(false);

  const [areas, setAreas] = useState([]);
  const [options, setOptions] = useState([]);

  const [profilesPerArea, setProfilesPerArea] = useState(0);
  const [technicalPerArea, setTechnicalPerArea] = useState(0);
  const [days, setDays] = useState(1);

  // Load area options
  useEffect(() => {
    let isAlive = true;
    hamService.getAreas().then((res) => {
      if (isAlive) {
        const areas = res.map((area) => ({
          label: area.name,
          value: area.code,
        }));
        setOptions(areas);
      }
    });
    return () => (isAlive = false);
  }, []);

  // Load config
  useEffect(() => {
    if (config) {
      const { areas, profilesPerArea, technicalPerArea, days } = config;
      setAreas(
        areas.map(({ code, name, openPositions }) => ({
          code,
          name,
          openPositions,
        })),
      );
      setProfilesPerArea(profilesPerArea || 0);
      setTechnicalPerArea(technicalPerArea || 0);
      setDays(days || 1);
    }
  }, [config]);

  useEffect(() => {
    if(config) {
      generateReport()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days])

  // Add area to table
  const addArea = (code, openPositions) => {
    if (areas.find((area) => area.code === code)) {
      notificationService.showWarning('Ooops', 'El área ya existe en la configuración');
      return false;
    }
    const newArea = {
      code,
      name: options.filter((area) => area.value === code)[0].label || code,
      openPositions,
    };
    setAreas([...areas, newArea]);
    return true;
  };

  // Delete area from table
  const deleteArea = (index) => {
    const newArr = [...areas];
    newArr.splice(index, 1);
    setAreas(newArr);
  };

  // Edit area from table
  const editArea = (newOpenPosition, index) => {
    const newArr = [...areas];
    newArr[index].openPositions = newOpenPosition;
    setAreas(newArr);
  };

  // Close modal
  const closeModal = () => setShowModal(false);

  // Generate report
  const generateReport = async () => {
    const data = {
      areas,
      profilesPerArea,
      technicalPerArea,
      days,
      pslId: config ? config.pslId : null,
    };
    if (await onGenerateReport(data)) {
      closeModal();
    }
  };

  const buttons = [
    {
      label: 'Generar reporte',
      onClick: generateReport,
      disabled: !areas.length,
      solid: true,
    },
  ];

  const dayOpts = generateOptionsFromNumber(TOTAL_DAYS);

  return (
    <>
      <div className="d-flex justify-content-end mb-3 spaced">
        <Button
          variant="btn-secondary"
          icon="refresh"
          onClick={generateReport}
          disabled={disabledUpdateButton || !areas.length}
        >
          Actualizar
        </Button>
        <Button icon="settings" onClick={() => setShowModal(true)}>
          Configurar Reporte
        </Button>
      </div>
      <ModalLeft isOpen={showModal} title="Configurar Reporte" closeModal={closeModal} buttons={buttons}>
        <TablePositions
          options={options}
          areas={areas}
          onAddArea={addArea}
          onDeleteArea={deleteArea}
          onEditArea={editArea}
        />
        <div className="required-position-container">
          <div className="required-position">
          <RequiredPosition
            label="Perfilaciones requeridas por posición abierta"
            value={profilesPerArea}
            setValue={setProfilesPerArea}
          />
          </div>
          <div className="required-position">
          <RequiredPosition
            label="Valoraciones requeridas por posición abierta"
            value={technicalPerArea}
            setValue={setTechnicalPerArea}
          />
          </div>
        </div>
      </ModalLeft>
      <div className="d-flex justify-content-end align-items-center">
        <span className="mx-2">Días de consultas</span>
        <span>
          <Select
            name="numberOfDays"
            options={dayOpts}
            value={days}
            onChange={(e) => setDays(parseInt(e.target.value))}
          />
        </span>
      </div>
    </>
  );
};

Configuration.propTypes = {
  config: PropTypes.object,
  onGenerateReport: PropTypes.func,
  disabledUpdateButton: PropTypes.bool,
};
