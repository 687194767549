import { Loader, NotificationsWrapper } from './components';
import React, { useEffect } from 'react';
import Routes from './Routes';
import AuthenticationConnectorInstance from './adapters/connectors/authentication.connector';

function App() {
  useEffect(() => {
    AuthenticationConnectorInstance.logger();
  }, []);

  return (
    <>
      <NotificationsWrapper />
      <Loader />
      <Routes />
    </>
  );
}

export default App;
