import React, { useEffect, useState } from 'react';
import { hamService, notificationService } from '../services';
import PropTypes from 'prop-types';

export const AreasContext = React.createContext(null);

export const AreasProvider = ({ children }) => {
  const [areas, setAreas] = useState(null);
  const [reloadValue, setReloadValue] = useState(false);

  const reload = () => setReloadValue(!reloadValue);

  useEffect(() => {
    let isAlive = true;
    hamService
      .getAreas()
      .then(isAlive && setAreas)
      .catch(() =>
        notificationService.showWarning(
          'Error interno del servidor',
          'Se ha generado un error interno, por favor contactar al admin',
        ),
      );
    return () => (isAlive = false);
  }, [reloadValue]);

  return <AreasContext.Provider value={{ areas, reload }}>{children}</AreasContext.Provider>;
};

AreasProvider.propTypes = {
  children: PropTypes.any,
};
