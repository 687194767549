import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const body = document.querySelector('body');
const openClass = 'sideout-is-open';

let timer;

export const SlideOut = ({ title, isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      timer = setTimeout(() => body.classList.add(openClass), 0);
    }
    return () => {
      body.classList.remove(openClass);
      clearTimeout(timer);
    };
  }, [isOpen]);

  const closeHandler = () => {
    body.classList.remove(openClass);
    timer = setTimeout(onClose, 400);
  };

  if (!isOpen) return null;

  const slideout = (
    <>
      <div className="fixed-top vh-100 overlay" onClick={closeHandler} />
      <div className="position-fixed d-flex flex-column vh-100 bg-white shadow-lg rounded-left overflow-hidden slideout">
        <div className="d-flex justify-content-between align-items-center flex-shrink-0 slideout__header">
          <h2 className="text-white">{title}</h2>
          <i className="material-icons text-white" onClick={closeHandler} role="button">
            close
          </i>
        </div>
        <div className="flex-grow-1 overflow-auto slideout__body">{children}</div>
      </div>
    </>
  );

  return createPortal(slideout, body);
};

SlideOut.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};
