import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../Shared';
import { hamService, notificationService } from '../../../../../services';

const ChangeState = ({ interview, closeModal }) => {

    const onCancelInterview = () => {
    (async () => {
      const result = await hamService.changeState(interview);
      if (result && result.error) {
        notificationService.showWarning('Oops', 'Ocurrió un error inesperado');
      } else {
        notificationService.showSuccess('Cambio exitoso', 'Se ha cambiado el estado de la entrevista con éxito');
      }

      return closeModal(true);
    })();
  };

  const modalButtons = [
    { label: 'Cancelar', onclick: () => closeModal(false), auxClass: 'btn-secondary' },
    { label: 'Continuar', onclick: onCancelInterview, auxClass: 'btn-primary' },
  ];

  return (
    <Modal
      show={!!interview}
      title="Cambiar estado entrevista"
      buttons={modalButtons}
      onClose={() => closeModal(false)}
    >
      <p>¿Está seguro de cambiar el estado de la entrevista?</p>
    </Modal>
  );
};

ChangeState.propTypes = {
  interview: PropTypes.object,
  closeModal: PropTypes.func,
};

export { ChangeState };
