import * as yup from 'yup';

// format defaultValues
export const setDefaultValues = (obj) => ({
  nameInterview: obj.name || '',
  subCategories: obj.subCategories || [],
  form: obj.form || '',
});

export const schema = yup.object().shape({
  name: yup.string().required(),
});
