import PropTypes from 'prop-types';
import React from 'react';
import { TableHead,TableRow, TableColumn } from './';
import { Check } from '../Check';


const Table = ({ data, thead, children, selected, onSelect, classTableName = '' }) => {
  return (
    <table className={`table ${classTableName}`}>
      <thead>
        <TableHead onSelect={onSelect} thead={thead} />
      </thead>
      <tbody>
        {data.map((row,index) => (
          <TableRow key={index} row={row} selected={selected}>
            {onSelect ? (
              <td>
                <Check active={row.id === selected} onClick={() => onSelect(row.id)} />
              </td>
            ) : null}
            {thead.map((value, index) => (
              <TableColumn
                row={row}
                key={index}
                rowValue={row[value.key]}
                headValue={value.key}
                className={value.className || ''}
              />
            ))}
          </TableRow>
        ))}
      </tbody>
      <tfoot>{children}</tfoot>
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.any,
  data: PropTypes.array,
  thead: PropTypes.array,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  classTableName: PropTypes.string
};

export { Table };
