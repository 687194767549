import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { UserContext } from '../../../../../context';
// service
import { hamService, notificationService } from '../../../../../services';
import { HamPermissions } from '../../../../../utils';
// components
import { FormPreferences, CheckAreas } from '../../InterviewerEdit';

const getInterviewerProfile = () => hamService.getInterviewerById('me');

export const MyInterviewerConfiguration = () => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const role = user.roles.find((permission) => HamPermissions.administrator.includes(permission));
    let isAlive = true;
    if (!profile) {
      getInterviewerProfile()
        .then((res) => ({
          ...res,
          isAdmin: !!role,
        }))
        .then(isAlive && setProfile)
        .catch(() => notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado'));
    }
    return () => (isAlive = false);
  }, [profile, user.roles]);

  if (!profile) {
    return null;
  }
  return (
    <div className="container my-4">
      <Tabs defaultActiveKey="areas" id="uncontrolled-tab-example">
        <Tab eventKey="areas" title="Áreas">
          <CheckAreas data={profile} meMode={true} />
        </Tab>
        <Tab eventKey="preferencias" title="Preferencias">
          <FormPreferences data={profile} meMode={true} />
        </Tab>
      </Tabs>
    </div>
  );
};
