import React from 'react';
import PropTypes from 'prop-types';

export const Icon = ({ icon, onClick, disabled = false, material = false, ...props }) => {
  let className = ['icon', material ? 'material-icons' : `icon-${icon}`, disabled ? 'disabled' : null].join(
    ' ',
  );
  return (
    <i className={className} onClick={(!disabled && onClick) || null} {...props}>
      {material ? icon : null}
    </i>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  material: PropTypes.bool,
};
