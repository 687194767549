import React, { useContext } from 'react';
import { SchedulingContext } from '../context';

export const AreaSelector = () => { 
  const { areas, updateSearchCriteria, searchCriteria } = useContext(SchedulingContext);
  const onChange = (e) => updateSearchCriteria('area', areas[e.target.value]);
  // No areas, no render
  if (!areas || !searchCriteria.area) {
    return null;
  }
  const selectedArea = areas.findIndex((a) => a.code === searchCriteria.area.code);

  return (
    <div className="form-group">
      <label htmlFor="area">Área</label>
      <select
        name="area"
        className="form-control bg-white"
        id="area"
        aria-describedby="areaHelp"
        required
        onChange={onChange}
        value={selectedArea}
      >
        {areas.map((area, index) => (
          <option key={area.code} value={index}>
            {area.name}
          </option>
        ))}
      </select>
      {/* <small id="areaHelp" className="form-text text-muted">
        Seleccione el área del conocimiento para el entrevistador
      </small> */}
    </div>
  );
};
