import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { useQuery, queryURL } from '../../../../../utils';

import { Search } from '..';
import { Button } from '../Button';

import './_topBar.scss';

export const TopBar = ({ buttonLabel, onClickButton, showButton = true, disableButton = false }) => {
  const history = useHistory();
  const { q, ...rest } = useQuery();
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(q); // Update the search input
  }, [q]);

  const onSearch = (e) => {
    const query = e.target.value;
    if (query.length > 1 || query.length === 0) {
      updateSearchQuery(query);
    }
  };

  const updateSearchQuery = _.debounce((q) => {
    const query = queryURL({ ...rest, page: 1, q });
    history.push(query);
  }, 500);

  return (
    <div className="top-bar-container">
      <div className="top-bar-container__search">
        <Search defaultValue={search} onChange={onSearch} id="searchInterviewer" />
      </div>
      <div className="top-bar-container__add">
        {showButton ? (
          <Button onClick={onClickButton} disabled={disableButton}>
            {buttonLabel || 'Añadir'}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  buttonLabel: PropTypes.string,
  onClickButton: PropTypes.func,
  showButton: PropTypes.bool,
  disableButton: PropTypes.bool,
};
