
import * as yup from 'yup';

const states = [
  { label: '', value: '' },
  { label: 'Pendiente', value: '0' },
  { label: 'Finalizada', value: '1' },
];

const schema = yup.object().shape({
  state: yup.string(),
  interviewType: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  area: yup.string()
});

const setDefaultValues = (data) => ({
  state: data.state || undefined,
  interviewType: data.interviewType || undefined,
  startDate: data.startDate || undefined,
  endDate: data.endDate || undefined,
  area: data.area || undefined
});

export { states, schema, setDefaultValues };
