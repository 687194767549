import React from 'react';
import PropTypes from 'prop-types';
import './RequiredPosition.scss'

export const RequiredPosition = ({ label, value, setValue }) => (
  <div className="row mx-0 py-3 bg-white align-items-center">
    <label className="col-sm-9">{label}</label>
    <div className="required-position-input-container">
      <input
        type="number"
        className="form-control bg-light"
        value={value}
        onChange={(e) => setValue(parseInt(e.target.value))}
      />
    </div>
  </div>
);

RequiredPosition.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};
