import React from 'react';
import PropTypes from 'prop-types';

import './DatePicker.scss';

export const DatePicker = ({ className = '', ...props }) => (
  <div className="datepicker">
    <input type="date" className={`form-control ${className}`} {...props} />
    <i className="material-icons text-primary">calendar_today</i>
  </div>
);

DatePicker.propTypes = {
  className: PropTypes.string,
};
