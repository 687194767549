import React, { useContext } from 'react';
import { SchedulingContext } from '../context';

export const TypeSelector = () => { 
  const { interviewTypes, updateSearchCriteria, searchCriteria } = useContext(SchedulingContext);
  const onChange = (e) => updateSearchCriteria('interviewType', interviewTypes[e.target.value]);
  // No types, no render
  if (!interviewTypes) {
    return null;
  }
  // No type selected, select the first one
  if (!searchCriteria.interviewType) {
    updateSearchCriteria('interviewType', interviewTypes[0]);
    return null;
  }
  const selectedType = interviewTypes.findIndex((a) => a.code === searchCriteria.interviewType.code);

  return (
    <div className="form-group">
      <label htmlFor="type">Tipo entrevista</label>
      <select
        name="type"
        className="form-control bg-white"
        id="type"
        aria-describedby="typeHelp"
        required
        onChange={onChange}
        value={selectedType}
      >
        {(interviewTypes || []).map((type, index) => (
          <option key={type.code} value={index}>
            {type.name}
          </option>
        ))}
      </select>
      {/* <small id="typeHelp" className="form-text text-muted">
        Seleccione el tipo de entrevista
      </small> */}
    </div>
  );
};
