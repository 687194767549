import React, { useContext } from 'react';
import { SchedulingContext } from '../context';

export const SubCategoriesSelector = () => {
  const { subCategories, updateSearchCriteria, searchCriteria } = useContext(SchedulingContext);
  const onChange = (e) => updateSearchCriteria('subCategory', subCategories[e.target.value]);
  // No levels, no render
  if (!subCategories) {
    return null;
  }
  // No level selected, select the first one
  if (!searchCriteria.subCategory) {
    updateSearchCriteria('subCategory', subCategories[0]);
    return null;
  }
  const selectedLevel = subCategories.findIndex((a) => a.code === searchCriteria.subCategory.code);

  return (
    <div className="form-group">
      <label htmlFor="level">Nivel</label>
      <select
        name="level"
        className="form-control  bg-white"
        id="level"
        aria-describedby="levelHelp"
        required
        onChange={onChange}
        value={selectedLevel}
      >
        {(subCategories || []).map((level, index) => (
          <option key={level.code} value={index}>
            {level.name}
          </option>
        ))}
      </select>
      <small id="levelHelp" className="form-text text-muted">
        Cuál es el nivel de la entrevista
      </small>
    </div>
  );
};
