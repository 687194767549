let queue = 0;
let listener = null;

const notifyListener = () => {
  if (queue > 0) {
    return listener(true);
  }
  listener(false);
};
const loaderService = {
  hide: () => {
    queue = queue - 1 < 0 ? 0 : queue - 1;
    notifyListener();
  },
  show: () => {
    queue = queue + 1;
    notifyListener();
  },
  subscribe: (cb) => (listener = cb),
};

export { loaderService };
