import React from 'react';
import PropTypes from 'prop-types';

export const Select = ({ options, placeholder = '', ...props }) => (
  <select className="custom-select" {...props}>
    {placeholder && (
      <option value="" disabled>
        {placeholder}
      </option>
    )}
    {options.map(({ label, value }, i) => (
      <option key={i} value={value}>
        {label}
      </option>
    ))}
  </select>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
