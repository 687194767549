import { Header, Bookmarks } from './components';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes';
import React from 'react';
import { useUserContext } from '../../context';

const Admin = () => {
  const { path, isExact } = useRouteMatch();
  const { user: { accessPages }, } = useUserContext();
  let contentAdmin = null;

  if (isExact) {
    contentAdmin =
      accessPages && accessPages.length ? (
        <Redirect to={`${path}${accessPages[0].link}`} />
      ) : (
        <p>Upss! parece que no tienes permisos</p>
      );
  }

  return (
    <>
      <Header />
      <Bookmarks />
      {contentAdmin}
      <AdminRoutes />
    </>
  );
};

export { Admin };
