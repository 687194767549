import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './WrapperPage.scss';

export const WrapperPage = ({ children, classes='', goback=true }) => {
  const history = useHistory();

  return (
    <div className="d-flex  container-fluid p-0">
      {goback && (
        <div className="bg-white py-3 px-3 column-left">
          <p className="btn-go-back" onClick={() => history.goBack()}>
            <span>
              <i className="material-icons">arrow_back_ios</i>Atrás
            </span>
          </p>
        </div>
        )}
      <div className="flex-grow-1 bg-light">
        <div className={classes}>{children}</div>
      </div>
    </div>
  );
};

WrapperPage.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.string,
  goback: PropTypes.bool
};
