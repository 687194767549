import * as _ from 'lodash';

export const areaToData = (areas, keyArea, keyType, levels) => {
  const area = areas[keyArea] || { name: '' };
  const type = area.interviewTypes ? area.interviewTypes.find(({ code }) => code === keyType) : { name: '' };
  const level = levels
    .map((level) =>
      type && type.subCategories
        ? type.subCategories.find(({ code }) => code === level).name
        : level,
    )
    .join(', ');
  return { area: area.name, type: type ? type.name : '', level };
};


export const thead = [
  { key: 'name', name: 'Nombre', sortKey: 'name', className: 'is-link' },
  { key: 'email', name: 'Correo', sortKey: 'email' },
  { key: 'area', name: 'Áreas', filterKey: 'filterByArea', options: [] },
  { key: 'type', name: 'Tipo', filterKey: 'filterByType', options: [] },
  { key: 'subCategory', name: 'Nivel', filterKey: 'filterByLevel', options: [] },
  { key: 'actions', name: 'Acciones', className: 'text-center' },
];

const getByKey = (obj, key) =>
  Object.entries(obj[key]).map(([, { name, code: value, ...res }]) => ({ name, value, ...res }));

// Set area selector
export const setAreaSelect = (areas) => {
  const newOptions = _.map(areas, (area, key) => ({
    name: area.name,
    value: key,
  }));
  thead.filter((item) => item.key === 'area')[0].options = _.orderBy(newOptions, 'name', 'asc');
  return thead;
};

// Update type when area selected
const setTypeLevelSelect = (areas, key) => {
  thead.find((item) => item.key === 'type').options = getByKey(areas[key], 'interviewTypes');
  return thead;
};

// Update sub category when type selected
const setSubCategory = (key) => {
  const type = thead.find((item) => item.key === 'type').options;
  thead.find((item) => item.key === 'subCategory').options = getByKey(
    type.find((e) => e.value === key),
    'subCategories',
  );
  return thead;
};
// Reset type/level when no area
export const resetTypeLevelSelect = () => {
  thead.find((item) => item.key === 'type').options = [];
  thead.find((item) => item.key === 'subCategory').options = [];
  return thead;
};

export const updateTHead = (e, update, areas) => {
  switch (e.target.id) {
    case 'filterByArea':
      document.querySelector('#filterByType').value = '';
      document.querySelector('#filterByLevel').value = '';
      e.target.value && update(setTypeLevelSelect(areas, e.target.value));
      break;
    case 'filterByType':
      document.querySelector('#filterByLevel').value = '';
      e.target.value && update(setSubCategory(e.target.value));
      break;
    default:
      update(resetTypeLevelSelect());
      break;
  }
};

