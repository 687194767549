import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({ variant = 'primary', icon = '', className = null, children, ...props }) => {
  const classList = [
    `btn d-flex justify-content-center align-items-center btn-${variant}`,
    className,
  ].join(' ');
  return (
    <button type="button" {...props} className={classList}>
      {icon && <i className="material-icons mr-3">{icon}</i>}
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};
