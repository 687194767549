import { request } from "./api.service";
import environments from "../environments";

function authorizedRequest(url, { method = 'GET', body = undefined, showLoading = true } = {}) {
  return request(`${environments.skills}${url}`, {
    body,
    method,
    showLoading,
  });
}

const getSkills = () => authorizedRequest(`public/skills`);


export { getSkills };
