import React from 'react';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';

import {
  Areas,
  ListInterviewers,
  Scheduling,
  InterviewerEdit,
  MyInterviewerConfiguration,
  Interviews,
  ScheduleAnalysis,
  MyInterviews,
  TemplateEvaluation,
} from './components';

import { AreasProvider } from '../../context';

export const AdminRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <AreasProvider>
      <Switch>
        <Route exact path={`${path}/areas`} component={Areas} />
        <Route exact path={`${path}/interviewers`} component={ListInterviewers} />
        <Route exact path={`${path}/interviewers/:id`} component={InterviewerEdit} />
        <Route exact path={`${path}/scheduling`} component={Scheduling} />
        <Route exact path={`${path}/my-interviewer-configuration`} component={MyInterviewerConfiguration} />
        <Route exact path={`${path}/interviews`} component={Interviews} />
        <Route exact path={`${path}/schedule-analysis`} component={ScheduleAnalysis} />
        <Route exact path={`${path}/my-interviews`} component={MyInterviews} />
        <Route
          exact
          path={`${path}/evaluation/:interviewId/:employeeId/:formId`}
          component={TemplateEvaluation}
        />
        <Route path="*">
          <Redirect to={`${path}/scheduling`} />
        </Route>
      </Switch>
    </AreasProvider>
  );
};
