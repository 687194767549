import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
// components
import { Sortable, Icon } from '../Shared';
import { CancelInterview, ChangeState, FindInterview, PrivateNotes, RescheduleIcon } from './components';
import { Scheduling } from '../Scheduling';
// services
import { hamService, notificationService } from '../../../../services';
// utils
import { useQuery, formatAppointmentDate, insertArray } from '../../../../utils';
import { useUserContext } from '../../../../context';

import './Interviews.scss';

const noWrapClass = 'text-nowrap';
const icons = 'text-nowrap text-center';

const thead = (isAdmin = false) => {
  let head = [
    { key: 'state', name: <i className="icon material-icons">filter_list</i>, className: noWrapClass },
    { key: 'employee', name: 'Empleado', sortKey: 'employee.name', className: noWrapClass },
    { key: 'employeeEmail', name: 'Email empleado', sortKey: 'employee.code' },
    {
      key: 'startDate',
      name: 'Fecha y hora',
      sortKey: 'startDate',
      className: 'testing-classname',
    },
    { key: 'area', name: 'Área', sortKey: 'area.name', className: noWrapClass },
    { key: 'type', name: 'Tipo', sortKey: 'type.name', className: noWrapClass },
    { key: 'level', name: 'Nivel', sortKey: 'level.name' },
    { key: 'notes', name: 'Notas', className: icons },
    { key: 'delete', name: 'Eliminar', className: icons },
    { key: 'reschedule', name: 'Reagendar', className: icons },
  ];
  if (isAdmin) {
    head = insertArray(head)({
      key: 'interviewer',
      name: 'Entrevistador',
      sortKey: 'interviewer.name',
      className: noWrapClass,
    })(1);
  }
  return head;
};

const setColorState = (state) => {
  switch (state) {
    case 0:
      return 'gray';
    case 1:
      return 'green';
    default:
      break;
  }
};
const tooltip = 'tooltip-reschedule';
const formatInterviewData = (interview, setDeleteInterview, setEditInterview, setInterview) => ({
  id: interview._id,
  state: (
    <OverlayTrigger
      placement="top"
      overlay={
        interview.state === 1 ? (
          <Tooltip id={tooltip}> Finalizada </Tooltip>
        ) : (
          <Tooltip id={tooltip}> Pendiente </Tooltip>
        )
      }
    >
      <span
        onClick={() => setInterview(interview)}
        className={`circle-lights ${setColorState(interview.state)}`}
      />
    </OverlayTrigger>
  ),
  interviewer: interview.interviewer ? interview.interviewer.name : interview.interviewerPslId,
  employee: `${interview.employee.name}`,
  employeeEmail: interview.employee.code,
  startDate: formatAppointmentDate(interview.startDate)(interview.endDate),
  area: interview.area.name,
  type: interview.area.interviewType.name,
  level: interview.area.interviewType.subCategory.name,
  notes: <PrivateNotes notes={interview.privateNotes || ''} />,
  delete: (
    <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-reschedule'}> Eliminar </Tooltip>}>
      <Icon disabled={false} icon="trash" onClick={() => setDeleteInterview(interview)} />
    </OverlayTrigger>
  ),
  reschedule: <RescheduleIcon setEditInterview={setEditInterview} interview={interview} />,
  interview: interview,
});

export const Interviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [editInterview, setEditInterview] = useState(null);
  const [reloadValue, setReloadValue] = useState(false);
  const [deleteInterview, setDeleteInterview] = useState(undefined);
  const [interview, setInterview] = useState(undefined);
  const [filterInterviews, setFilterInterviews] = useState({});
  const { user } = useUserContext();
  const history = useHistory();
  const urlParams = useQuery();

  useEffect(() => {
    let isAlive = true;
    hamService
      .getInterviews(urlParams, filterInterviews)
      .then(({ data, total }) => {
        if (isAlive) {
          const resInterviewers = data.map((interview) =>
            formatInterviewData(interview, setDeleteInterview, setEditInterview, setInterview),
          );
          setInterviews(resInterviewers);
          setTotalItems(total);
        }
      })
      .catch(() => notificationService.showWarning('Ooops!', 'Ocurrió un error inesperado'));
    return () => (isAlive = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, reloadValue, editInterview, filterInterviews]);

  const onCloseModal = (result) => {
    if (result) {
      setReloadValue(!reloadValue);
    }
    setEditInterview(undefined);
    setInterview(undefined);
    setDeleteInterview(undefined);
  };

  const tableAttributes = {
    thead: thead(user.isAdmin),
    data: interviews,
    totalItems,
  };

  return (
    <div className="container-fluid py-4 interviews">
      {editInterview && (
        <>
          <p className={'h-6 text-center'}>{`Reprogramar a ${editInterview.employee.name}`}</p>
          <Scheduling editInterview={editInterview} onCloseCalendar={onCloseModal} />
        </>
      )}
      <div className="container-fluid py-4">
        <div className="card card-table">
          <FindInterview filter={filterInterviews} setFilter={setFilterInterviews} />
          {!interviews || !interviews.length ? (
            <Alert variant="warning" className="mt-3">
              No se encuentran entrevistas
            </Alert>
          ) : (
            <Sortable selected={editInterview ? editInterview._id : ''} {...tableAttributes} />
          )}
        </div>
      </div>
      <CancelInterview interview={deleteInterview} closeModal={onCloseModal} />
      <ChangeState interview={interview} closeModal={onCloseModal} />
    </div>
  );
};
