import React from 'react';
import logo from '../../../../assets/images/tam-logo.svg';
import { useUserContext } from '../../../../context';
import { MegaMenu } from './components/MegaMenu';

import { megaMenu } from './routesMenu';

export const Header = () => {
  const { onLogout, user } = useUserContext();

  return (
    <header className="d-flex align-items-center bg-dark text-white header">
      <img src={logo} alt="Logo" className="ml-3" height="50" />

      <MegaMenu data={megaMenu} />

      <div className="text-left ml-auto mr-4">
        <b>Bienvenid@</b>
        <div>{user.name}</div>
      </div>
      <div className="d-flex header-logout" onClick={onLogout}>
        <i className="material-icons m-auto">exit_to_app</i>
      </div>
    </header>
  );
};
