import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { ModalLeftHeader, ModalLeftFooter } from './components';
import { Form } from 'react-bootstrap';

const body = document.querySelector('body');
const openClass = 'modal-is-open';

const ModalLeft = ({
  isOpen = false,
  title = '',
  children,
  closeModal = null,
  submit = null,
  titleButton = '',
  buttons = [],
}) => {
  useEffect(() => {
    if (isOpen) body.classList.add(openClass);
    return () => body.classList.remove(openClass);
  }, [isOpen]);

  const onClose = (result) => {
    body.classList.remove(openClass);
    setTimeout(() => {
      closeModal(result || false);
    }, 400);
  };

  const onSubmit = async (...e) => {
    if (await submit(...e)) {
      onClose(true);
    }
  };

  if (!isOpen) return null;

  const formButtons = [
    { label: 'Cancelar', variant: 'btn btn-secondary', onClick: () => onClose(false) },
    { label: titleButton, type: 'submit' },
  ];

  const contentClasses = 'modal-left-content d-flex flex-column flex-grow-1';

  const contentModal = (
    <>
      <div className="fixed-top vh-100 overlay" />
      <div className="modal-left d-flex flex-column position-fixed vh-100 bg-white">
        <ModalLeftHeader title={title} onClose={onClose} />
        {buttons.length ? (
          <div className={contentClasses}>
            <div className="flex-grow-1 modal-left-space">{children}</div>
            {buttons.length ? <ModalLeftFooter buttons={buttons} /> : null}
          </div>
        ) : (
          <Form onSubmit={onSubmit} className={contentClasses} autoComplete="off">
            <div className="flex-grow-1  modal-left-space">{children}</div>
            <ModalLeftFooter buttons={formButtons} />
          </Form>
        )}
      </div>
    </>
  );
  return createPortal(contentModal, body);
};

ModalLeft.propTypes = {
  children: PropTypes.any.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleButton: PropTypes.string,
  buttons: PropTypes.array,
};

export { ModalLeft };
