import React from 'react';
import PropTypes from 'prop-types';

const PageItem = ({ label, onClick, active, disabled }) => {
  const classnames = `page-item ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`;
  return (
    <li className={classnames}>
      <button className="page-link p-0" onClick={onClick} disabled={disabled}>
        {label}
      </button>
    </li>
  );
};

PageItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PageItem;
