import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { hamService } from '../../../../../services';
import { schedulingService } from '../services';

// Create the context
export const SchedulingContext = React.createContext({
  areas: null,
  interviewTypes: null,
  subCategories: null,
  interviewers: null,
  search: null,
  searchCriteria: null,
  updateSearchCriteria: null,
  loadOmitted: null,
  editInterview: null,
  setEditInterview: null,
});

export const SchedulingConsumer = SchedulingContext.Consumer;

const loadAvailability = (interviewers) =>
  Promise.all(
    (interviewers || []).map(async (interviewer) => {
      const appointments = await schedulingService.getAppointments(interviewer._id);
      return { ...interviewer, appointments };
    }),
  );

// Component
export const SchedulingProvider = ({ editInterviewProp = null, children }) => {
  const [areas, setAreas] = useState(null);
  const [interviewTypes, setInterviewTypes] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({
    area: null,
    interviewType: null,
    subCategory: null
  });
  const [interviewers, setInterviewers] = useState(null);
  const [editInterview, setEditInterview] = useState(editInterviewProp);

  const search = () => schedulingService.searchInterviewers(searchCriteria).then(loadAvailability).then(setInterviewers);

  const updateSearchCriteria = (key, value) => {
    setSearchCriteria((oldCriteria) => ({ ...oldCriteria, [key]: value }));
  };

  const loadOmitted = (interviewer) => {
    const interviewerIndex = interviewers.findIndex((value) => value._id === interviewer._id);
    const newInterviewers = [...interviewers];
    newInterviewers[interviewerIndex].appointments.availability.push(
      ...newInterviewers[interviewerIndex].appointments.omitted,
    );
    newInterviewers[interviewerIndex].appointments.omitted = [];
    setInterviewers(newInterviewers);
  };

  const [selectedArea, setSelectedArea] = useState(searchCriteria.area);
  const [selectedInterviewTypes, setSelectedInterviewTypes] = useState(searchCriteria.interviewType);

  // Did mount
  useEffect(() => {
    let isAlive = true;
    if (!areas) {
      (async () => {
        const hamAreas = await hamService.getAreas();
        if (!isAlive) {
          return;
        }
        setAreas(hamAreas);
        updateSearchCriteria('area', hamAreas[0]);
      })();
    }
    if (editInterview && !!areas) {
      updateSearchCriteria(
        'area',
        areas.find((area) => area.code === editInterview.area.code),
      );
    }
    return () => (isAlive = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, editInterview]);

  // Area updated
  useEffect(() => {
    if (!selectedArea) {
      setInterviewTypes(null);
      return;
    }
    setInterviewTypes(selectedArea.interviewTypes);
    if (!editInterview) {
      updateSearchCriteria('interviewType', selectedArea.interviewTypes[0]);
    } else {
      const area = areas.find((area) => area.code === editInterview.area.code);
      const type = area.interviewTypes.find((type) => type.code === editInterview.area.interviewType.code);
      updateSearchCriteria('interviewType', type);
      updateSearchCriteria('english', editInterview.english);
    }
  }, [areas, editInterview, selectedArea]);

  // interview type updated
  useEffect(() => {
    if (!selectedInterviewTypes) {
      setSubCategories(null);
      return;
    }
    setSubCategories(selectedInterviewTypes.subCategories);
    if (!editInterview) {
      updateSearchCriteria('subCategory', selectedInterviewTypes.subCategories[0]);
    } else {
      const type = interviewTypes.find((type) => type.code === editInterview.area.interviewType.code);
      const subCategory = type.subCategories.find(
        (subCategory) => subCategory.code === editInterview.area.interviewType.subCategory.code,
      );
      updateSearchCriteria('subCategory', subCategory);
      updateSearchCriteria('english', editInterview.english);
    }
  }, [areas, editInterview, interviewTypes, selectedInterviewTypes]);

  useEffect(() => {
    setSelectedArea(searchCriteria.area);
    if (searchCriteria.interviewType) {
      setSelectedInterviewTypes(searchCriteria.interviewType);
    }
  }, [searchCriteria]);

  useEffect(() => {
    setEditInterview(editInterviewProp);
    setInterviewers(null);
  }, [editInterviewProp]);

  return (
    <SchedulingContext.Provider
      value={{
        areas,
        interviewTypes,
        subCategories,
        interviewers,
        search,
        searchCriteria,
        updateSearchCriteria,
        loadOmitted,
        editInterview,
        setEditInterview
      }}
    >
      {children}
    </SchedulingContext.Provider>
  );
};

SchedulingProvider.propTypes = {
  children: PropTypes.any,
  editInterviewProp: PropTypes.any,
};
